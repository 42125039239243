import React from 'react';
import { Link } from 'react-router-dom';
import { I18n, LABEL_CATEGORY } from '../components/I18n';
import { JsonTableData } from '../components/Model';
import {
  defined,
  includesAll,
  joinWith,
  Optional,
  returnIf,
  setDefault,
} from '../components/Util';

// テーブルの部品の種類を決めるための定数
const COMPONENT_KIND = {
  choices: ['race_category', 'race_kind'] as string[],
  toggles: ['sgt'] as string[],
  options: ['tyre', 'circuit', 'manufacturer'] as string[],
} as const;

const PAGE_PATH = {
  year: '/year/race_year/',
  race: '/race/race_id/',
  race_list: '/race_list',
  circuit: '/circuit/circuit/',
} as const;

export class Interval<T> {
  public from: T;
  public to: T;
  constructor(from: T, to?: T) {
    this.from = from;
    this.to = to ? to : from;
  }
  isSingle(): boolean {
    return this.from === this.to;
  }
}

export interface TableState {
  primaryColumn?: { columnId: string; desc: boolean };
  pageIndexOrRowId?: number | string;
  visibleRowIdPattern?: Optional<string>;
}

export interface TableSectionState {
  optionsState: Record<string, string>;
  tableState: TableState;
}

export type PageState = {
  initialTable: Optional<string>;
  tableStates: Record<string, TableSectionState>;
};

export type PathData = { [key: string]: PathData | string };

export type RaceData = {
  [key: string]: { year: number; round: number; stopped: boolean };
};

export class RaceViewData {
  constructor(
    readonly year: number,
    readonly round: number,
    readonly stopped: boolean,
    readonly isFirstRace: boolean,
    readonly isLastRace: boolean
  ) {}
}

export class RacesViewData {
  readonly racesData: Record<string, RaceViewData>;

  constructor(rawData: [number, number, number][]) {
    this.racesData = {};
    rawData.forEach(([raceId, year, round], index) => {
      if (!defined(raceId) || !defined(year) || !defined(round)) {
        return;
      }
      const prevData = this.racesData[raceId - 1];
      let stopped = false;
      if (defined(prevData)) {
        if (prevData.year === year && prevData.round + 1 !== round) {
          stopped = true;
        } else if (prevData.year !== year && round !== 1) {
          stopped = true;
        }
      }
      const isFirstRace = prevData?.year !== year;
      const isLastRace = rawData[index + 1]?.[1] !== year;
      this.racesData[raceId] = new RaceViewData(
        year,
        round,
        stopped,
        isFirstRace,
        isLastRace
      );
    }, []);
  }

  get(raceId: Optional<string | number>): Optional<RaceViewData> {
    return returnIf(raceId, (raceId) => this.racesData[raceId]);
  }

  getFirstRaceId(year: number): number {
    return Object.entries(this.racesData)
      .filter(([, raceData]) => raceData.year === year)
      .reduce<number>(
        (firstRaceId, [raceId]) => Math.min(firstRaceId, parseInt(raceId)),
        Number.MAX_SAFE_INTEGER
      );
  }

  getLastRaceId(year: number): number {
    return Object.entries(this.racesData)
      .filter(([, raceData]) => raceData.year === year)
      .reduce<number>(
        (lastRaceId, [raceId]) => Math.max(lastRaceId, parseInt(raceId)),
        Number.MIN_SAFE_INTEGER
      );
  }
}

class IntRange {
  constructor(readonly start: number, readonly end: number) {}

  toString(delimiter: string): string {
    if (this.start === this.end) {
      return String(this.start);
    } else if (this.start + 1 === this.end) {
      return `${this.start}${delimiter}${this.end}`;
    } else {
      return `${this.start}~${this.end}`;
    }
  }
}
export class IntRanges {
  private readonly ranges: IntRange[];
  constructor(value: string) {
    const result: IntRange[] = [];
    value.split('~').forEach((v) => {
      const [start, end] = v
        .split('-')
        .map((v) => returnIf(v, (v) => parseInt(v)));
      if (defined(start)) {
        result.push(new IntRange(start, defined(end) ? end : start));
      }
    });
    this.ranges = result;
  }

  private separate(
    range: IntRange,
    endRaceIds: Iterable<number> | ArrayLike<number>
  ): IntRange[] {
    const ids = Array.from(endRaceIds).sort();
    return [...ids, range.end].map((end, index, array) => {
      const start = returnIf(array[index - 1], (s) => s + 1) ?? range.start;
      return new IntRange(start, end);
    });
  }

  isYear(): boolean {
    return !defined(this.ranges[0]) || this.ranges[0].start > 1800;
  }

  separateByYear(racesViewData: RacesViewData): IntRange[] {
    if (this.isYear()) {
      return this.ranges;
    }
    return this.ranges.reduce<IntRange[]>(
      (intervals, interval, index, array) => {
        const startYear = racesViewData.get(interval.start)?.year;
        const endYear = racesViewData.get(interval.end)?.year;
        if (defined(startYear) && defined(endYear) && startYear !== endYear) {
          // 範囲の先頭と末尾が異なるなら分割対象

          // 分割位置。setなのは先頭と末尾が1年違いのとき、
          // 下記のlastとfirstが同じ値で重複するのを取り除くため
          const endRaceIds = new Set<number>();

          if (racesViewData.get(array[index - 1]?.end)?.year === startYear) {
            // 前の範囲の最後が別年、あるいは前の範囲が存在しないなら分ける必要なし
            const last = racesViewData.getLastRaceId(startYear);
            endRaceIds.add(last);
          }
          if (racesViewData.get(array[index + 1]?.start)?.year === endYear) {
            // 次の範囲の先頭が別年、あるいは前の範囲が存在しないなら分ける必要なし
            const first = racesViewData.getFirstRaceId(endYear);
            endRaceIds.add(first - 1);
          }
          return [...intervals, ...this.separate(interval, endRaceIds)];
        } else {
          // 範囲の先頭と末尾が同じなら分ける必要なし
          return [...intervals, interval];
        }
      },
      []
    );
  }

  toString(racesViewData: RacesViewData): string {
    const ranges = this.separateByYear(racesViewData);
    const delimiter = ', ';
    return ranges.map((range) => range.toString(delimiter)).join(delimiter);
  }
}

const createIntervalLink = (num: number, element: JSX.Element): JSX.Element => {
  if (num > 1800) {
    return (
      <Link key={num} to={{ pathname: PAGE_PATH.year + `${num}` }}>
        {element}
      </Link>
    );
  } else {
    return (
      <Link key={num} to={{ pathname: PAGE_PATH.race + `${num}` }}>
        {element}
      </Link>
    );
  }
};

const createCircuitLinkElement = (link: string, i18n: I18n): JSX.Element => {
  const elements: (JSX.Element | string)[] = [];
  link
    .split('~')
    .sort()
    .forEach((value, index) => {
      if (index > 0) {
        elements.push(', ');
        // カンマの手前で区切る
        elements.push(<wbr key={index} />);
      }
      const element = (
        <Link key={value} to={{ pathname: PAGE_PATH.circuit + `${value}` }}>
          {i18n.DATA.localize(value, 'circuit')}
        </Link>
      );
      elements.push(element);
    });
  return <>{elements}</>;
};

const isIntRangesString = (link: string): boolean => {
  return defined(/^[0-9\-~]+$/.exec(link));
};

export const createLinkElement = (
  link: string,
  raceData: RaceData,
  i18n: I18n
): JSX.Element => {
  if (!isIntRangesString(link)) {
    return createCircuitLinkElement(link, i18n);
  }

  const racesViewData = new RacesViewData(
    Object.entries(raceData).map(([raceId, { year, round }]) => [
      parseInt(raceId),
      year,
      round,
    ])
  );
  const ranges = new IntRanges(link).separateByYear(racesViewData);
  const elements: (JSX.Element | string)[] = [];
  let currentYear: Optional<number>;

  const createLinkFunc = (id: number) => {
    const subElements: (JSX.Element | string)[] = [];
    if (id < 1800) {
      const { year, round } = raceData[id] ?? {};
      if (currentYear !== year) {
        if (elements.slice(-1)[0] === ', ') {
          // 年の手前のカンマでのみ区切る
          subElements.push(<wbr key={id} />);
        }
        subElements.push(`${String(year)} Rd.`);
        currentYear = year;
      }
      subElements.push(`${String(round)}`);
    } else {
      subElements.push(`${id} `);
    }
    return createIntervalLink(id, <>{subElements}</>);
  };

  for (const [index, range] of ranges.entries()) {
    if (index > 0) {
      elements.push(', ');
    }
    if (range.start === range.end) {
      elements.push(createLinkFunc(range.start));
    } else {
      const delimiter = range.start + 1 === range.end ? ', ' : '~';
      elements.push(
        createLinkFunc(range.start),
        delimiter,
        createLinkFunc(range.end)
      );
    }
  }
  return (
    <>
      {elements} &nbsp;
      <RaceListLink link={link}>
        {i18n.LABELS.localize('link_detail', LABEL_CATEGORY.TABLE_COMMON)}
      </RaceListLink>
    </>
  );
};

export const RaceListLink: React.FC<{
  link: string;
  children?: React.ReactNode;
}> = ({ link, children }) => {
  if (isIntRangesString(link) && !new IntRanges(link).isYear()) {
    const linkProp = {
      pathname: PAGE_PATH.race_list,
      search: `visible_row_ids=${link}`,
      hash: 'result',
    };
    return <Link to={linkProp}>{children}</Link>;
  } else {
    return <></>;
  }
};

const expandToRaceIds = (intervals: string[]): string[] => {
  const raceIds: string[] = [];
  for (const [index, part] of intervals.entries()) {
    if (part === '~') {
      // skip
    } else if (part === '-') {
      const startRaceId = parseInt(intervals[index - 1] ?? '');
      const endRaceId = parseInt(intervals[index + 1] ?? '');
      const length = endRaceId - startRaceId - 1;
      if (length > 0) {
        const addedIds = Array.from(Array(length).keys()).map((k) =>
          (startRaceId + k + 1).toString()
        );
        raceIds.push(...addedIds);
      } else {
        // ~の前後の数字が逆転している場合はエラーなので即座に空を返す
        return [];
      }
    } else {
      raceIds.push(part);
    }
  }
  return raceIds;
};

const parseIntervals = (value: string): string[] => {
  const result: string[] = [];
  value.split('~').forEach((value0, i) => {
    const value1 = joinWith(
      value0.split('-').map((value1) => value1),
      '-'
    );
    if (i > 0) {
      result.push('~');
    }
    result.push(...value1);
  });
  return result;
};

export const createRoundsInfo = (races: string, raceData: RaceData): string => {
  const intervals = parseIntervals(races);
  const raceIds = expandToRaceIds(intervals);
  const allRacesInYear: Record<string, string[]> = {};
  Object.entries(raceData).forEach(([raceId, { year }]) => {
    setDefault(allRacesInYear, `${year}`, []).push(raceId);
  });
  const racesInYear: Record<string, string[]> = {};
  raceIds.forEach((raceId) => {
    setDefault(racesInYear, `${String(raceData[raceId]?.year)}`, []).push(
      raceId
    );
  });
  const entryToAllRounds = Object.entries(racesInYear).every(
    ([year, raceIds]) => includesAll(raceIds, allRacesInYear[year])
  );

  if (entryToAllRounds) {
    return '';
  } else {
    return intervals
      .map((item) => {
        if (item === '~') {
          return ', ';
        } else if (item === '-') {
          return '~';
        } else {
          return raceData[item]?.round.toString();
        }
      })
      .join('');
  }
};

export const expandLinkToIds = (link: string): string[] => {
  if (isIntRangesString(link)) {
    const intervals = parseIntervals(link);
    const raceIds = expandToRaceIds(intervals);
    return raceIds;
  } else {
    return link.split('~');
  }
};

const jsonPrefix = '/json/';

const dataMap: Map<string, unknown> = new Map();

export interface FileState<T> {
  isLoading: boolean;
  data: T;
  error?: Error;
}

export const useBase2 = <T, V>(
  path: string,
  initialValue: T,
  map: (data: V) => T
): FileState<T> => {
  // return useBaseSync<T, V>(path, initialValue, map);
  return useBaseAsync<T, V>(path, initialValue, map);
};

export const useBaseSync = <T, V>(
  path: string,
  initialValue: T,
  map: (data: V) => T
): FileState<T> => {
  const cachedData = dataMap.get(path) as Optional<T>;
  if (!defined(cachedData)) {
    const request = new XMLHttpRequest();
    request.open('GET', jsonPrefix + path, false); // `false` で同期リクエストになる
    request.send(null);
    if (request.status === 200) {
      const json = JSON.parse(request.responseText) as V;
      const data = map(json);
      dataMap.set(path, data);
      return { data, isLoading: false };
    } else {
      return {
        data: initialValue,
        error: new Error(`${request.status} ${request.responseText}`),
        isLoading: false,
      };
    }
  } else {
    return { data: cachedData, isLoading: false };
  }
};
export const useBaseAsync = <T, V>(
  path: string,
  initialValue: T,
  map: (data: V) => T
): FileState<T> => {
  const cachedData = dataMap.get(path) as Optional<T>;

  const initialState = defined(cachedData)
    ? { data: cachedData, isLoading: false }
    : {
        data: initialValue,
        isLoading: true,
      };
  const [state, setState] = React.useState(initialState as FileState<T>);
  React.useEffect(() => {
    if (defined(cachedData)) {
      // キャッシュを使用する場合はfetchを呼ばない
      if (state.data !== cachedData) {
        // 2回目以降の処理ではinitialStateに代入されないため、setする
        setState({ data: cachedData, isLoading: false });
      }
      return;
    }
    // state.isLoadingは2回目以降の実行だと、この時点でfalseの可能性がある
    // そのためtrueに変更する
    setState((prev) => ({ ...prev, isLoading: true }));
    // isLoadingはthenの中で即時反映されない可能性があるので条件分岐には使用しない
    let mounted = true;
    fetch(jsonPrefix + path)
      .then((res) => res.json())
      .then(
        (result: V) => {
          if (mounted) {
            const data = map(result);
            setState({ data, isLoading: false });
            dataMap.set(path, data);
          }
        },
        (error: Error) => {
          if (mounted) {
            setState({ data: initialValue, error, isLoading: false });
          }
        }
      );
    return () => {
      mounted = false;
      setState((prev) => ({ ...prev, isLoading: false }));
    };
  }, [path]);
  return state;
};

export const useTableData2 = <T, V>(
  path: string,
  initialValue: T,
  map: (data: V) => T
): FileState<T> => {
  return useBase2('data/' + path, initialValue, map);
};

export const fetchJsonData = <T, V = T>(
  path: string,
  map: (data: V) => T
): T => {
  const cachedData = dataMap.get(path) as Optional<T>;
  if (!defined(cachedData)) {
    throw fetch(path)
      .then((res) => res.json())
      .then((data) => dataMap.set(path, map(data)));
  } else {
    return cachedData;
  }
};

const fetchBase = <T, V = T>(path: string, map: (data: V) => T): T => {
  const cachedData = dataMap.get(path) as Optional<T>;
  if (!defined(cachedData)) {
    throw fetch(jsonPrefix + path)
      .then((res) => res.json())
      .then((data) => dataMap.set(path, map(data)));
  } else {
    return cachedData;
  }
};

const useDataBase = <T,>(path: string): T => {
  return fetchBase<T, T>('data/' + path, (data) => data);
};

export const usePath = (): PathData => {
  return useDataBase('paths.json');
};

export const useRedirectData = (): { [key: string]: string } => {
  return useDataBase('redirects.json');
};

export const usePageData = (path: string): string[] => {
  return useDataBase(path + '/tables.json');
};

export const fetchRaceData = (): RaceData => {
  return fetchBase<RaceData, JsonTableData>(
    'setting/race/race.json',
    (jsonData) => {
      const raceData: RaceData = {};
      for (const { data } of jsonData.rows) {
        const [raceId, year, round] = data.map((cell) =>
          parseInt(cell[0]?.[0] ?? '')
        );
        if (defined(raceId) && defined(year) && defined(round)) {
          raceData[raceId] = { year, round, stopped: false };
        }
      }
      for (const [raceId, data] of Object.entries(raceData)) {
        const prevData = raceData[parseInt(raceId) - 1];
        if (defined(prevData)) {
          if (
            prevData.year === data.year &&
            prevData.round + 1 !== data.round
          ) {
            data.stopped = true;
          } else if (prevData.year !== data.year && data.round !== 1) {
            data.stopped = true;
          }
        }
      }
      return raceData;
    }
  );
};

export interface Table {
  tableId: string;
  requiredChoiceOptions: Record<string, Set<Optional<string>>>;
  requiredToggleOptions: Record<string, string>;
  optionalOptions: Record<string, Set<string>>;
  data: Record<string, string>[];
}

export const createTableName = (
  tableId: string,
  map: Record<string, string>
): string => {
  const { keys, values } = Object.entries(map).reduce(
    ({ keys, values }, [key, value]) => {
      if (defined(value)) {
        return { keys: [...keys, key], values: [...values, value] };
      } else {
        return { keys, values };
      }
    },
    { keys: [], values: [] } as { keys: string[]; values: string[] }
  );
  const parts = [tableId];
  parts.push(...keys, ...values);
  return parts.join('-');
};

export const parseTableName = (
  path: string
): [string, Record<string, string>] => {
  const parts = path.split('-');
  const title = parts.splice(0, 2 - (parts.length % 2)).join('-');
  const map: Record<string, string> = {};
  const halfLength = parts.length / 2;
  for (let i = 0; i < halfLength; i++) {
    const key = parts[i];
    const value = parts[halfLength + i];
    if (defined(key) && defined(value)) {
      map[key] = value;
    }
  }
  return [title, map];
};

export const createOptions = (paths: string[]): Table[] => {
  const data: { [name: string]: { [name: string]: string }[] } = {};
  for (const path of paths) {
    const [title, map] = parseTableName(path);
    setDefault(data, title, []).push(map);
  }

  const result: Table[] = [];
  for (const [tableId, maps] of Object.entries(data)) {
    const options: Record<string, Set<string>> = {};
    for (const map of maps) {
      for (const [key, value] of Object.entries(map)) {
        setDefault(options, key, new Set<string>()).add(value);
      }
    }
    const requiredChoiceOptions: Record<string, Set<Optional<string>>> = {};
    const requiredToggleOptions: Record<string, string> = {};
    const optionalOptions: Record<string, Set<string>> = {};

    for (const [name, values] of Object.entries(options)) {
      if (COMPONENT_KIND.choices.includes(name)) {
        let v: Set<Optional<string>> = values;
        if (!maps.every((map) => defined(map[name]))) {
          v = new Set<Optional<string>>(values).add(undefined);
        }
        requiredChoiceOptions[name] = v;
      } else if (COMPONENT_KIND.toggles.includes(name)) {
        const v = Array.from(values)[0];
        if (defined(v)) {
          requiredToggleOptions[name] = v;
        }
      } else if (COMPONENT_KIND.options.includes(name)) {
        optionalOptions[name] = values;
      } else {
        throw new Error(name);
      }
    }
    result.push({
      tableId,
      requiredChoiceOptions,
      requiredToggleOptions,
      optionalOptions,
      data: maps,
    });
  }
  return result;
};
