export type Optional<T> = T | undefined;

export const defined = <T,>(obj: Optional<T> | null | undefined): obj is T => {
  return obj !== null && obj !== undefined;
};

export const assertDefined: <T>(
  obj: Optional<T>,
  message?: string
) => asserts obj is T = (obj, message = '') => {
  if (!defined(obj)) {
    throw new Error(
      `The value is null but it should not be. message=${message}`
    );
  }
};

export const returnOptional = <T,>(flag: boolean, obj: T): Optional<T> => {
  return flag ? obj : void 0;
};

export const returnIf = <T, V>(
  flag: Optional<T>,
  func: (flag: T) => Optional<V>
): Optional<V> => {
  return defined(flag) ? func(flag) : void 0;
};

export const optional = <T, V>(
  value: Optional<T>,
  func: (value: T) => V
): Optional<V> => {
  return value ? func(value) : void 0;
};

export const joinWith = <T,>(array: T[], sep: T): T[] => {
  if (array[0]) {
    const result: T[] = [];
    result.push(array[0]);
    array.slice(1).forEach((a) => result.push(sep, a));
    return result;
  } else {
    return [];
  }
};

export const wrapBySpan = (array: JSX.Element[]): JSX.Element[] => {
  return array.map((a, index) => {
    return <span key={index}>{a}</span>;
  });
};

export function setDefault<T extends number | string | symbol, V>(
  obj: Record<T, V> | Map<T, V>,
  prop: T,
  defaultValue: V
): V {
  if (obj instanceof Map) {
    const v = obj.get(prop);
    if (!defined(v)) {
      obj.set(prop, defaultValue);
      return defaultValue;
    } else {
      return v;
    }
  } else {
    return defined(obj) && Object.prototype.hasOwnProperty.call(obj, prop)
      ? obj[prop]
      : (obj[prop] = defaultValue);
  }
}

export function joinArray<T>(array: T[][]): T[] {
  return array.reduce((acc: T[], cur: T[]): T[] => {
    return [...acc, ...cur];
  }, []);
}

/**
 * フリガナを正規化する。濁点と半濁点を取り除き平仮名に変換する
 * @param str
 * @returns
 */
export function normalizeKana(str: string): string {
  // 濁点と半濁点の除去
  str = str
    .split('')
    .map((c) => c.normalize('NFD'))
    .join('');
  // 小文字を大文字に
  str = str.toUpperCase();
  // カタカナを平仮名に変換
  return str.replace(/[\u30a1-\u30f6]/g, function (match: string) {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });
}

export const isOtherInitial = (str: string): boolean => {
  // あ-ん、A-Z
  const regexp = /[\u3041-\u3093A-Z]/mu;
  return !(defined(str[0]) && regexp.test(str[0]));
};

export const mergeRecords = <T,>(
  values: Record<string, T>[],
  initial: Record<string, T> = {}
): Record<string, T> =>
  values.reduce((target, v) => ({ ...target, ...v }), initial);

export const filterRecords = <T,>(
  value: Record<string, T>,
  filter: (key: string) => boolean
): Record<string, T> =>
  mergeRecords(
    Object.entries(value).map(([k, v]) => (filter(k) ? { [k]: v } : {}))
  );

export const mapRecord = <T extends string, V>(
  original: Record<T, V>,
  func: (key: T, value: V) => [T, V]
): Record<T, V> => {
  return Object.fromEntries(
    Object.entries<V>(original).map(([key, value]) => func(key as T, value))
  ) as Record<T, V>;
};

export const keys = <T extends string | number | symbol>(
  obj: Record<T, unknown>
): T[] => {
  const result: T[] = [];
  for (const o in obj) {
    result.push(o);
  }
  return result;
};

const sortAndStringify = (value: unknown): string => {
  return JSON.stringify(value, (_, v: unknown) => {
    if (!(v instanceof Array || v === null) && typeof v === 'object') {
      const obj = v as Record<string, unknown>;
      return Object.keys(obj)
        .sort()
        .reduce((r, k) => {
          // ソートしたキーの順番に元の値を代入
          r[k] = obj[k];
          return r;
        }, {} as Record<string, unknown>);
    } else {
      return v;
    }
  });
};

export const isSameObject = (
  value1: Record<string, unknown>,
  value2: Record<string, unknown>
): boolean => sortAndStringify(value1) === sortAndStringify(value2);

export const removeDuplicated = <T, U>(
  values: T[],
  key: Optional<(v: T) => U> = undefined
): T[] => {
  const hashValues = values.map((v) => (key ? key(v) : v));
  return values.filter(function (elem, index) {
    return index === hashValues.indexOf(key ? key(elem) : elem);
  });
};

export const includesAll = <T,>(array: T[], subArray: Optional<T[]>): boolean =>
  defined(subArray) && subArray.every((v) => array.includes(v));

export const delay = (ms: number): Promise<void> =>
  new Promise<void>((resolve) => setTimeout(resolve, ms));

const multiplyRem = (
  rem: Optional<string | number>,
  ratio: number
): Optional<string | number> => {
  if (defined(rem)) {
    if (typeof rem === 'number') {
      return rem * ratio;
    } else {
      const m = /(.+)rem/.exec(rem);
      if (defined(m) && defined(m[1])) {
        const remNumber = Number(m[1]);
        if (!isNaN(remNumber)) {
          return `${remNumber * ratio}rem`;
        }
      }
    }
  }
  return undefined;
};

export const getIconSize = (
  baseRem: Optional<string | number>
): Optional<string | number> => multiplyRem(baseRem, 1.5);
