/**
 * Models for raw JSON data.
 */

import { defined, Optional } from './Util';

export type JsonData = {
  [key: string]: JsonTableData;
};

export interface JsonTableData {
  columns: JsonColumn[];
  rows: { id: string; data: JsonCell[][] }[];
}

export type JsonColumn = [string, string, string, string];

export type JsonCell = [
  string,
  Optional<string>,
  Optional<string>,
  boolean,
  Optional<string>
];

/**
 * Models for formatted JSON data.
 */

export interface TableData {
  columns: ColumnData[];
  rows: RowData[];
}

export const COLUMN_KIND = {
  RANK_KEY: 'rank_key',
  RANK_CALC_AGG_PRO: 'rank_calc_agg_pro',
  RANK_ACC: 'rank_acc',
  INDIV_CALC_AGG: 'indiv_calc_agg',
  INDIV_PRO: 'indiv_pro',
} as { [name: string]: string };

export const COLUMN_KINDS = Object.values(COLUMN_KIND);

function getColumnKind(value: string): ColumnKind {
  const c = COLUMN_KINDS.filter((c) => c === value)[0];
  if (defined(c)) {
    return c;
  } else {
    throw Error(value);
  }
}

export type ColumnKind = typeof COLUMN_KIND[keyof typeof COLUMN_KIND];

export interface ColumnData {
  title: string;
  kind?: ColumnKind;
  sortable?: boolean;
  mergeSameValue?: boolean;
}

export interface RowData {
  id: string;
  cells: CellData[];
}

export interface CellData {
  values: CellValue[];
}

export interface CellValue {
  value: string;
  continuing: boolean;
  link?: string;
  races?: string;
  diff?: string;
}

export function toData(jsonData: JsonTableData): TableData {
  const columns: ColumnData[] = [];
  for (const jsonColumn of jsonData.columns) {
    const column: ColumnData = {
      title: jsonColumn[0],
      kind: getColumnKind(jsonColumn[1]),
      sortable: toBoolean(jsonColumn[2]),
      mergeSameValue: toBoolean(jsonColumn[3]),
    };
    columns.push(column);
  }
  const rows: RowData[] = jsonData.rows.map((row) => ({
    id: row.id,
    cells: row.data.map(
      (cell): CellData => ({
        values: cell.map((data: JsonCell): CellValue => {
          const c = {
            value: data[0],
            continuing: data[3],
          } as CellValue;
          if (defined(data[1])) {
            c.link = data[1];
          }
          if (defined(data[2])) {
            c.races = data[2];
          }
          if (defined(data[4])) {
            c.diff = data[4];
          }
          return c;
        }),
      })
    ),
  }));
  return { columns, rows };
}

const toBoolean = (value: string): boolean => {
  return value.toUpperCase() === 'TRUE';
};
