import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchJsonData } from '../util/DataUtil';
import DrawerLeft from './DrawerLeft';
import HeaderButton from './HeaderButton';
import { getLogger } from './Logger';
import { MenuViewData } from './ViewModel';

const logger = getLogger(import.meta.url);

export type HeaderData = {
  buttons: Record<string, MenuViewData>;
};

const Header: React.FC = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const handleDrawerToggle = () => {
    setDrawerVisible(!drawerVisible);
  };
  logger.debug(`START`);

  const headerData = fetchJsonData<HeaderData>(
    '/layouts/header.json',
    (v) => v
  );

  return (
    <>
      <AppBar
        sx={{
          left: '0px',
        }}
      >
        <Toolbar
          sx={{
            // 狭い時はロゴを中央寄せ
            justifyContent: { xs: 'center', sm: 'left' },
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{
              // 狭い時のみ表示。ボタンを縦センタリングするためinline-flexを使用
              display: { xs: 'inline-flex', sm: 'none' },
              // 左寄せのためpositionとleftを指定
              position: 'absolute',
              left: 0,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Link to={{ pathname: '/' }}>
            <Box
              sx={{
                // 広い時は拡大し、ボタンとのパディングを指定
                height: { xs: '24px', sm: '28px' },
                pr: { xs: 0, sm: 3 },
              }}
            >
              <img src="/img/logo.svg" width="auto" height="100%" alt="logo"/>
            </Box>
          </Link>
          {Object.entries(headerData.buttons).map(([buttonId, items]) => {
            return (
              <HeaderButton
                key={buttonId}
                buttonId={buttonId}
                items={items}
                sx={{
                  // inlineにしないとセンタリングされない
                  display: { xs: 'none', sm: 'inline' },
                  verticalAlign: 'bottom',
                }}
              />
            );
          })}
        </Toolbar>
      </AppBar>
      <DrawerLeft
        buttonsData={headerData.buttons}
        visible={drawerVisible}
        setVisible={setDrawerVisible}
      />
    </>
  );
};

export default Header;
