import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import * as History from 'history';
import React from 'react';
import { BrowserRouter, Redirect, Route, useHistory } from 'react-router-dom';
import { usePath, useRedirectData } from '../util/DataUtil';
import AboutPage from './AboutPage';
import { SendAnalyticsData } from './AnalyticsUtil';
import Container from './Container';
import {
  ActiveOnlyContextProvider,
  ShowAdditionalInfoContextProvider,
} from './Context';
import Header from './Header';
import StyledComponents, { ScrollTopFab } from './StyledComponents';
import TopPage from './TopPage';

const ScrollToTop: React.FC = () => {
  const history = useHistory();
  React.useEffect(() => {
    const unregister = history.listen(
      (_location: History.Location, action: History.Action) => {
        // ブラウザの戻ると進むはPOPイベントになるので無視する
        if (action !== 'POP') {
          window.scrollTo(0, 0);
        }
      }
    );
    return () => {
      unregister();
    };
  }, []);
  return <></>;
};

const Default = (): React.ReactElement => {
  const paths = usePath();
  const redirectPaths = useRedirectData();
  const routes: JSX.Element[] = Object.entries(redirectPaths).map(
    ([key, value]) => {
      key = '/' + key;
      value = '/' + value;
      return (
        <Route
          key={key}
          path={key}
          render={() => {
            return <Redirect to={value} />;
          }}
        />
      );
    }
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <BrowserRouter>
        <SendAnalyticsData />
        <ScrollToTop />
        <Header />
        <Box
          component="main"
          sx={{
            mt: 10, // 上からのマージン
            mb: 8, // 下からのマージン
            // mx: 2, // ランキングページのボタンが見切れるためコメントアウト
            width: '100%',
            overflowX: 'hidden', // テーブルスクロールさせる
          }}
        >
          <Route
            path={'/preview'}
            render={
              // コンポーネントの見た目を確認するためのパス
              () => <StyledComponents />
            }
          />
          <Route path={'/about'} render={() => <AboutPage />} />
          <Route exact path={'/'} render={() => <TopPage />} />
          <ActiveOnlyContextProvider>
            <ShowAdditionalInfoContextProvider>
              <Container parentPath="/" paths={paths} />
            </ShowAdditionalInfoContextProvider>
          </ActiveOnlyContextProvider>
          <ScrollTopFab />
        </Box>
        {routes}
      </BrowserRouter>
    </Box>
  );
};

export default Default;
