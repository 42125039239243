import React from 'react';
// import './../assets/scss/App.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Default from './Default';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

const theme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true, // falseだと初回のmediaQueryがすべてfalse
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: 'body2',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          size: 'small',
          backgroundColor: '#fff',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          dense: true,
          disablePadding: true,
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#192672',
    },
    secondary: {
      main: 'rgb(220, 0, 78)',
    },
    background: {
      default: '#f7f7f7',
      paper: '#fff',
    },
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: '1.5rem',
      letterSpacing: '0em',
    },
    h2: {
      fontWeight: 300,
      fontSize: '1.25rem',
      letterSpacing: '0em',
    },
    h3: {
      fontWeight: 300,
      fontSize: '1rem',
      letterSpacing: '0em',
    },
    body3: {
      fontSize: '0.625rem',
    },
    button: {
      textTransform: 'none',
    },
  },
});

const App = (): React.ReactElement => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <React.Suspense fallback={<></>}>
          <Default />
        </React.Suspense>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
