import React, { ReactNode, createContext, useContext, useState } from 'react';

export interface ActiveOnlyContextProps {
  value: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActiveOnlyContext = createContext({} as ActiveOnlyContextProps);

export const useActiveOnlyContext = (): ActiveOnlyContextProps => {
  return useContext(ActiveOnlyContext);
};

export const ActiveOnlyContextProvider = (props: {
  children: ReactNode;
}): JSX.Element => {
  const [value, setValue] = useState(false);

  return (
    <ActiveOnlyContext.Provider value={{ value, setValue }}>
      {props.children}
    </ActiveOnlyContext.Provider>
  );
};

export type ShowAdditionalInfoContextProps = ActiveOnlyContextProps;

const ShowAdditionalInfoContext = createContext(
  {} as ShowAdditionalInfoContextProps
);

export const useAdditionalInfoContext = (): ShowAdditionalInfoContextProps => {
  return useContext(ShowAdditionalInfoContext);
};

export const ShowAdditionalInfoContextProvider = (props: {
  children: ReactNode;
}): JSX.Element => {
  const [value, setValue] = useState(false);

  return (
    <ShowAdditionalInfoContext.Provider value={{ value, setValue }}>
      {props.children}
    </ShowAdditionalInfoContext.Provider>
  );
};
