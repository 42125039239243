import React from 'react';
import { Route } from 'react-router-dom';
import { getLogger } from './Logger';
import Page from './Page';
import ListPage from './ListPage';
import { Helmet } from 'react-helmet-async';

const logger = getLogger(import.meta.url);

type JsonPathData = { [key: string]: JsonPathData | string };

type ContainerProps = {
  parentPath: string;
  paths: JsonPathData;
};

const ReplaceCanonicalLink: React.FC = () => {
  return (
    <Helmet>
      <link
        rel="canonical"
        href={window.location.origin + window.location.pathname}
      />
    </Helmet>
  );
};

const Container: React.FC<ContainerProps> = ({ parentPath, paths }) => {
  const routers: React.ReactElement[] = [];
  // 画面遷移のルーターを生成する。表示するページの要素ではない
  for (const [path, childPath] of Object.entries(paths)) {
    if (typeof childPath === 'string') {
      // ページが指定された
      routers.push(
        <Route
          key={path}
          path={parentPath + path}
          render={() => {
            logger.debug(`START Route.render ${path} ${childPath}`);
            return (
              <>
                <ReplaceCanonicalLink />
                <Page filePath={childPath} />
              </>
            );
          }}
        />
      );
    } else {
      // コンテナが指定された
      routers.push(
        <Route
          key={path}
          path={parentPath + path}
          render={() => {
            logger.debug(`START Route.render ${path}`);
            return (
              <Container
                parentPath={parentPath + path + '/'}
                paths={childPath}
              />
            );
          }}
        />
      );
    }
  }
  // コンテナのパスと完全一致 (exact) するならリンクを表示
  // トップページは除く
  if (parentPath !== '/') {
    routers.push(
      <Route
        exact
        key={parentPath}
        path={parentPath}
        render={() => (
          <>
            <ReplaceCanonicalLink />
            <ListPage parentPath={parentPath} paths={Object.keys(paths)} />
          </>
        )}
      />
    );
  }
  return <>{routers}</>;
};

export default Container;
