import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  TableState as TS,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
} from 'react-table';

import {
  Backdrop,
  Box,
  BoxProps,
  TableCell,
  TableCellProps,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import {
  RaceData,
  TableState,
  createLinkElement,
  createRoundsInfo,
  expandLinkToIds,
  fetchRaceData,
} from '../util/DataUtil';
import {
  ActiveOnlyContextProps,
  useActiveOnlyContext,
  useAdditionalInfoContext,
} from './Context';
import { I18n, LABEL_CATEGORY, useI18n } from './I18n';
import { getLogger } from './Logger';
import {
  Column,
  ColumnInstance,
  TableInstance,
  TableOptions,
  useTable,
} from './ReactTableWrapper';
import {
  ActiveOnlyButton,
  CheckboxLabel,
  RankDiffIcon,
  RequiredOptionSecondButton,
} from './StyledComponents';
import TableTabs from './TableTabs';
import { Optional, defined, wrapBySpan } from './Util';
import {
  Rank,
  RankingCell,
  RankingColumn,
  RankingColumnId,
  RankingRow,
  RankingTableData,
  RankingValue,
} from './ViewModel';

const logger = getLogger(import.meta.url);

// Column.Cell に関数を指定する場合の引数
// react-tableのどこかに定義されているはずだが、探すのが大変なので
// 使用する属性をここで定義
type RenderCellProps = TableInstance<RankingRow, RankingColumn> & {
  row: Row<RankingRow>;
};

const COLUMN_ID_RANK = '_id_rank_';
const COLUMN_ID_ACTIVE_RANK = '_id_active_rank_';
const COLUMN_ID_ADDITIONAL_INFO = '_id_additional_info_';

type ValueElementProps = {
  raceData: RaceData;
  i18n: I18n;
  hasRank: boolean;
  canAbbreviateRoundInfo: boolean;
  showRacesAsLink: boolean;
  onLinkDisplayed: Optional<(link: string) => void>;
  onClick?: () => void;
};

const TABLE_VIEW_MODE = {
  MULTIPLE_COLUMNS: 'multiple_columns',
  SINGLE_COLUMN_AND_INFO: 'single_column_and_info',
  SINGLE_COLUMN: 'single_column',
} as const;

type TableViewMode = typeof TABLE_VIEW_MODE[keyof typeof TABLE_VIEW_MODE];

type GlobalFilter = {
  // 参戦中選手のみを表示するならtrue
  isActiveOnly: boolean;
  // RowIdでコンテンツをフィルタする。レース一覧に使用
  visibleRowIdPattern?: Optional<string>;
};

export const createValueElement = (
  rankingValue: RankingValue,
  withAdditional: boolean, // value の後ろにdiffなど追加情報を表示
  {
    raceData,
    i18n,
    hasRank,
    showRacesAsLink, // 追加情報をリンクで表示するaタグを入れるか否か
    canAbbreviateRoundInfo,
    onLinkDisplayed,
    onClick,
  }: ValueElementProps
): React.ReactElement => {
  const value = rankingValue.getLocalizedValue(i18n);
  const link = rankingValue.getLink();
  const races = showRacesAsLink ? rankingValue.getRaces() : undefined;
  const diff = rankingValue.getDiff(i18n);
  const ratio = rankingValue.getRatio();

  let valueElement: React.ReactElement = <> {value}</>;
  if (defined(link)) {
    // 参戦歴のマシン名などをリンクで表示
    valueElement = (
      <Link to={link.getLinkData()} onClick={onClick}>
        {valueElement}
      </Link>
    );
  }
  if (defined(races) && races !== '') {
    if (!defined(link) && hasRank && defined(onLinkDisplayed)) {
      // リンクを含まず、かつランキングを持つ列。ランキングの達成レースをリンクで表示
      valueElement = (
        <a
          href="#"
          onClick={(event) => {
            event.preventDefault();
            onLinkDisplayed(races);
          }}
        >
          {valueElement}
        </a>
      );
    } else if (!canAbbreviateRoundInfo) {
      // 車番やカテゴリなどランキングの列でない場合
      // 全ラウンドに出場していない場合は追加表示
      let rounds = createRoundsInfo(races, raceData);
      if (rounds.length > 0) {
        rounds = ` (Rd.${rounds})`;
      }
      valueElement = (
        <>
          {valueElement}
          {rounds}
        </>
      );
    }
  }

  // diffとratioは画面幅が広い時のみ表示する
  let additionalData = '';
  if (defined(ratio)) {
    additionalData += ` (${String(ratio.top)}/${String(ratio.bottom)})`;
  }
  if (defined(diff) && diff !== '') {
    additionalData += ` (${diff})`;
  }

  return (
    <>
      {valueElement}
      {withAdditional ? additionalData : <></>}
    </>
  );
};

export const createCellElement = (
  rankingCell: Optional<RankingCell>,
  valueElementProps: ValueElementProps
): JSX.Element => {
  const elements: React.ReactElement[] = [];
  for (const rankingValue of rankingCell?.getValues() ?? []) {
    elements.push(
      <>
        <Box
          component={'span'}
          sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }}
        >
          {createValueElement(rankingValue, false, valueElementProps)}
        </Box>
        <Box
          component={'span'}
          sx={{ display: { xs: 'block', md: 'none', lg: 'block' } }}
        >
          {createValueElement(rankingValue, true, valueElementProps)}
        </Box>
      </>
    );
  }
  // spanにkeyを振る
  return <>{wrapBySpan(elements)}</>;
};

const createRankColumn = (
  id: string,
  isActive: boolean,
  theme: Theme,
  i18n: I18n
): Column<RankingRow, RankingColumn> => {
  let headerId = '';
  if (id === COLUMN_ID_RANK) {
    headerId = 'rank';
  } else if (id === COLUMN_ID_ACTIVE_RANK) {
    headerId = 'active_rank';
  }
  const renderRankCell = (props: RenderCellProps) => {
    const { row } = props;
    for (const cell of row.cells) {
      if (cell.column.isSorted) {
        const rankingCell = row.original.getCell(cell.column.id);
        const rank = isActive ? rankingCell?.activeRank : rankingCell?.rank;
        if (defined(rank)) {
          const showActive = !isActive && defined(rankingCell?.activeRank);
          return createRankElement(rank, showActive, theme, i18n);
        }
      }
    }
    return null;
  };
  return {
    Header: i18n.LABELS.localize(headerId, LABEL_CATEGORY.PRODUCER),
    id,
    Cell: renderRankCell,
    disableSortBy: true,
  };
};

export const createRankElement = (
  rank: Rank,
  showActive: boolean,
  theme: Theme,
  i18n: I18n
): JSX.Element => {
  return (
    <RankDiffIcon diffValue={rank.diff} theme={theme}>
      {rank.getLocalizedValue(i18n)}
      {showActive ? '*' : ''}
    </RankDiffIcon>
  );
};

const createAdditionalInfoColumn = (
  id: string,
  i18n: I18n
): Column<RankingRow, RankingColumn> => {
  const raceData = fetchRaceData();
  const renderCell = ({ row }: RenderCellProps) => {
    const elements: React.ReactElement[] = [];
    for (const cell of row.cells) {
      // ソート中の列の追加情報を取得して表示
      if (cell.column.isSorted) {
        const rankingCell = row.original.getCell(cell.column.id);
        if (defined(rankingCell)) {
          for (const value of rankingCell.getValues()) {
            const races = value.getRaces();
            if (defined(races)) {
              const element = createLinkElement(races, raceData, i18n);
              elements.push(<Fragment key={races}>{element}</Fragment>);
            }
          }
        }
      }
    }
    return <>{elements}</>;
  };
  return {
    Header: i18n.LABELS.localize('additional_info', LABEL_CATEGORY.PRODUCER),
    id,
    Cell: renderCell,
    disableSortBy: true,
  };
};

interface ViewData {
  columns: Column<RankingRow, RankingColumn>[];
  rows: RankingRow[];
}

const createViewData = (
  tableData: RankingTableData,
  raceData: RaceData,
  theme: Theme,
  onLinkDisplayed: Optional<(link: string) => void>,
  i18n: I18n
): ViewData => {
  logger.debug(
    `Create view model. columns=${String(tableData.getColumns().length)}`
  );

  const columns: Column<RankingRow, RankingColumn>[] = tableData
    .getColumns()
    .map((column) => {
      const id = column.id;
      return {
        Header: id.localize(i18n),
        // アプリ内で、リンク元で指定されたIDから列を特定するときに使う
        id: id.getFullId(),
        accessor: (row: RankingRow) => row.id,
        // ReactTableがソートのために必要としている
        data: column,
        Cell: (props: RenderCellProps) =>
          createCellElement(props.row.original.getCell(id), {
            raceData,
            i18n,
            canAbbreviateRoundInfo:
              props.row.original.canAbbreviateRoundInfo(id),
            // 追加情報がテーブルに表示されている場合、racesをリンクで表示しない
            showRacesAsLink: !props.columns.some(
              (column) =>
                column.isVisible && column.id === COLUMN_ID_ADDITIONAL_INFO
            ),
            hasRank: column.hasRank(),
            onLinkDisplayed,
          }),
        sortType: (
          row1: Row<RankingRow>,
          row2: Row<RankingRow>,
          _columnId: string,
          isDesc: Optional<boolean>
        ) => row1.original.compareTo(row2.original, id, isDesc),
        disableSortBy: !column.canSort(),
      };
    });

  if (tableData.hasRank()) {
    const rankColumn = createRankColumn(COLUMN_ID_RANK, false, theme, i18n);
    columns.unshift(rankColumn);
  }
  if (tableData.hasActiveRank()) {
    const activeRankColumn = createRankColumn(
      COLUMN_ID_ACTIVE_RANK,
      true,
      theme,
      i18n
    );
    columns.unshift(activeRankColumn);
  }
  columns.push(createAdditionalInfoColumn(COLUMN_ID_ADDITIONAL_INFO, i18n));
  return {
    columns,
    rows: tableData.rows,
  };
};

type SortRule = { column: Optional<RankingColumn>; desc: boolean };

const getInitialSortingRule = (
  tableData: RankingTableData,
  initialState: TableState
): SortRule => {
  const { columns } = tableData;
  const { primaryColumn } = initialState;
  const filteredColumns = columns.filter(
    (c) => c.id.getFullId() === primaryColumn?.columnId
  );
  if (filteredColumns.length > 0) {
    return { column: filteredColumns[0], desc: primaryColumn?.desc ?? false };
  } else {
    return { column: columns.filter((c) => c.hasRank())[0], desc: false };
  }
};

const createComponentOptions = (
  viewData: ViewData,
  initialState: TableState,
  { column: sortedColumn, desc }: SortRule,
  activeOnly: boolean,
  tableViewMode: TableViewMode,
  onStateChanged?: (state: TableState) => void
): TableOptions<RankingRow, RankingColumn> => {
  const { columns, rows } = viewData;
  logger.debug(`Create table options. columns=${columns.length}`);

  // デフォルトで非表示にする列の特定
  const hiddenColumns: string[] = [];
  getColumnsVisibility(
    columns,
    sortedColumn?.id,
    tableViewMode,
    activeOnly
  ).forEach(([column, hidden]) => {
    if (hidden && defined(column.id)) {
      hiddenColumns.push(column.id);
    }
  });

  // デフォルトのページ番号、選択する行の特定
  let pageIndex = 0;
  let selectedRowIds: Record<string, boolean> = {};
  const { pageIndexOrRowId } = initialState;
  if (typeof pageIndexOrRowId === 'number') {
    pageIndex = pageIndexOrRowId;
  } else if (typeof pageIndexOrRowId === 'string') {
    selectedRowIds = { [pageIndexOrRowId]: true };
  }

  const sortBy = sortedColumn
    ? [{ id: sortedColumn.id.getFullId(), desc }]
    : [];

  const globalFilterValue: GlobalFilter = {
    isActiveOnly: activeOnly,
    visibleRowIdPattern: initialState.visibleRowIdPattern,
  };
  const tableOptions: TableOptions<RankingRow, RankingColumn> = {
    columns,
    data: rows,
    useControlledState: (state: TS<RankingRow>) => {
      React.useEffect(() => {
        // ページに変更通知。ブラウザバック時に状態を復元するため
        if (defined(onStateChanged)) {
          onStateChanged(convertToTableState(state));
        }
      }, [state]);
      return React.useMemo(() => state, [state]);
    },
    initialState: {
      pageSize: 50,
      pageIndex,
      hiddenColumns,
      globalFilter: globalFilterValue,
      sortBy,
      selectedRowIds,
    },
    getRowId,
    globalFilter: createGlobalFilter,
  };
  return tableOptions;
};

interface AggregatorOptions {
  values: { id: string; value: string; available: boolean }[];
  selectedId: string;
}

/**
 * rankingColumnsが空またはaggが1種類ならaggは空
 * initialが未定義なら最初の一つのagg
 */
const createAggregatorOptions = (
  tableData: RankingTableData,
  initialColumn: Optional<RankingColumn>,
  i18n: I18n
): AggregatorOptions => {
  const categories: string[] = tableData.getRankingColumnCategories();
  return {
    values: categories.map((v) => ({
      id: v,
      value: i18n.LABELS.localize(v, LABEL_CATEGORY.TABLE_CATEGORY),
      available: true,
    })),
    selectedId: initialColumn?.id.getCategory() ?? '',
  };
};

const createGlobalFilter = (
  rows: Row<RankingRow>[],
  _columnIds: string[],
  globalFilterValue: GlobalFilter
): Row<RankingRow>[] =>
  rows.filter((row) => {
    if (globalFilterValue.isActiveOnly) {
      if (!row.original.isActive()) {
        return false;
      }
    }
    if (defined(globalFilterValue.visibleRowIdPattern)) {
      const ids = expandLinkToIds(globalFilterValue.visibleRowIdPattern);
      if (!ids.includes(row.original.id)) {
        return false;
      }
    }
    return true;
  });

const getColumnsVisibility = <
  T extends {
    id?: Optional<string>;
    canSort?: boolean;
    disableSortBy?: boolean;
    data?: Optional<RankingColumn>;
  }
>(
  columns: T[],
  sortedColumnId: Optional<RankingColumnId>,
  tableViewMode: TableViewMode,
  activeOnly: boolean
): [T, boolean][] => {
  const canSort = (column: T): boolean => {
    if (defined(column.canSort)) {
      return column.canSort;
    } else if (defined(column.disableSortBy)) {
      return !column.disableSortBy;
    } else {
      return false;
    }
  };
  const currentId = sortedColumnId?.getFullId();
  const currentCategory = sortedColumnId?.getCategory();
  const currentColumn = columns.filter(
    (column) => column.data?.id.getFullId() === currentId
  )?.[0];

  const result: [T, boolean][] = [];
  for (const column of columns) {
    let hidden = false;
    if (canSort(column)) {
      if (column.data?.id.getFullId() !== currentId) {
        // ソート可能だが、ソート中でない列
        if (
          [
            TABLE_VIEW_MODE.SINGLE_COLUMN,
            TABLE_VIEW_MODE.SINGLE_COLUMN_AND_INFO,
          ].some((v) => v === tableViewMode)
        ) {
          // 画面幅が狭い場合、または追加情報の表示時は、ソート中でない列を非表示
          hidden = true;
        } else if (
          !defined(column.id) ||
          column.data?.id.getCategory() !== currentCategory
        ) {
          // 画面幅が広い場合、ソート列と同じaggregatorでなければ非表示
          hidden = true;
        }
      }
    } else {
      if (
        column.id === COLUMN_ID_ADDITIONAL_INFO &&
        !(
          (currentColumn?.data?.hasAdditionalInfo() ?? false) &&
          tableViewMode === TABLE_VIEW_MODE.SINGLE_COLUMN_AND_INFO
        )
      ) {
        // 追加情報の列は、ソート中の列が追加情報を持ち、かつ
        // view mode が追加情報の表示を示しているときのみ表示する
        hidden = true;
      }
      // ソートできない列は基本的に表示、ただし順位と現役順位は排他
      if (column.id === COLUMN_ID_RANK && activeOnly) {
        hidden = true;
      } else if (column.id === COLUMN_ID_ACTIVE_RANK && !activeOnly) {
        hidden = true;
      }
    }
    result.push([column, hidden]);
  }
  return result;
};

const createAggregatorButton = (
  columns: ColumnInstance<RankingRow, RankingColumn>[],
  initialState: AggregatorOptions
) => {
  logger.debug(`Create a button for aggregator.`);
  return initialState.values.length > 0 ? (
    <RequiredOptionSecondButton
      sx={{
        flexShrink: 0, // 画面幅が狭まっても各ボタンの横幅を維持する
      }}
      values={initialState.values}
      selectedId={initialState.selectedId}
      canUnselect={false}
      onChanged={(id) => {
        for (const c of columns) {
          if (c.canSort && c.data?.id.getCategory() === id) {
            // ソートはこのタイミングで切り替わりレンダリングが走る
            // その後で表示列が切り替わり2回目のレンダリングが走る
            c.toggleSortBy();
            break;
          }
        }
      }}
    />
  ) : (
    <></>
  );
};

const createActiveOnlyButton = (
  show: boolean,
  { value, setValue }: ActiveOnlyContextProps,
  i18n: I18n
) => {
  logger.debug(`Create a button for active only.`);
  const id = 'active_only';
  return show ? (
    <ActiveOnlyButton
      values={[
        {
          id,
          value: i18n.LABELS.localize(id, LABEL_CATEGORY.TABLE_COMMON),
          available: true,
        },
      ]}
      selectedId={value ? id : ''}
      canUnselect={true}
      onChanged={(id) => setValue(id.length > 0)}
    />
  ) : (
    <></>
  );
};

const createTabs = (
  columns: ColumnInstance<RankingRow, RankingColumn>[],
  tableViewMode: TableViewMode
) => {
  logger.debug(`Create tab element. columns=${columns.length}`);
  const sortedColumn = columns.filter((c) => c.isSorted)[0];
  const selectedId = sortedColumn?.data?.id.getFullId() ?? '';
  // view mode を multiple  にすることで、同一aggregatorのその他の列が取れる
  // activeOnlyはどちらでも結果に影響ない
  const visibleColumns = getColumnsVisibility(
    columns,
    sortedColumn?.data?.id,
    TABLE_VIEW_MODE.MULTIPLE_COLUMNS,
    false
  )
    .filter(([column, hidden]) => !hidden && column.canSort)
    .map(([column]) => column);

  return tableViewMode !== TABLE_VIEW_MODE.MULTIPLE_COLUMNS &&
    visibleColumns.length > 1 ? (
    <TableTabs
      values={visibleColumns.map((c) => ({
        id: c.id,
        displayName: c.Header?.toString() ?? '',
      }))}
      selectedId={selectedId}
      onChanged={(value) => {
        columns.filter((c) => c.id === value).forEach((c) => c.toggleSortBy());
      }}
    />
  ) : (
    <></>
  );
};

interface StyledTableCellProps extends TableCellProps {
  header?: boolean;
  selected?: boolean;
  theme?: Theme;
}
export const StyledTableCell = ({
  header = false,
  selected,
  theme,
  ...props
}: StyledTableCellProps): JSX.Element => {
  const keepAll = {
    wordBreak: 'keep-all', // 日本語は途中で改行しない。英語はデフォルト
    // whiteSpace: 'nowrap',
  } as React.CSSProperties;

  const headerStyle = header
    ? {
        verticalAlign: 'top',
      }
    : {};

  // row-selected と同じ色
  // https://github.com/mui/material-ui/blob/master/packages/mui-material/src/TableRow/TableRow.js
  const showSelected = defined(selected) && selected && defined(theme);
  const backgroundColor = showSelected
    ? {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      }
    : {};
  return (
    <TableCell
      {...props}
      style={{ ...headerStyle, ...keepAll }}
      sx={{ px: 1, ...props.sx, ...backgroundColor }}
    />
  );
};

const convertToTableState = (state: TS): TableState => {
  // TableStateのうちページIndexとrowIdのフィルタは状態が
  // 変化しても維持する。
  const tableState: TableState = {
    pageIndexOrRowId: state.pageIndex,
    visibleRowIdPattern: (state.globalFilter as GlobalFilter)
      .visibleRowIdPattern,
  };
  const sortBy = state.sortBy;
  if (defined(sortBy[0])) {
    // descのデフォルト値はfalse
    // https://react-table-v7.tanstack.com/docs/api/useSortBy
    tableState.primaryColumn = {
      columnId: sortBy[0].id,
      desc: sortBy[0].desc ?? false,
    };
  }
  return tableState;
};

const getRowId = (row: RankingRow): string => row.id;

const getSelectedRowIds = (selectedRowIds: Record<string, boolean>): string[] =>
  Object.entries(selectedRowIds)
    .filter(([, v]) => v)
    .map(([k]) => k);

const selectedRowsAllVisible = (
  visibleRows: Row<RankingRow>[],
  ids: string[]
): boolean => ids.every((id) => visibleRows.map((r) => r.id).includes(id));

export type TableBaseProps<T> = BoxProps & {
  tableData: T;
  isLoading: boolean;
  buttons?: React.ReactElement;
  onLinkDisplayed?: (link: string) => void;
  onReadyToScroll?: () => void;
};

export type RankingTableBaseProps = TableBaseProps<RankingTableData> & {
  initialState: TableState;
  showAggregatorButtons?: Optional<boolean>;
  onStateChanged?: (newState: TableState) => void;
  createTable: (
    reactTable: TableInstance<RankingRow, RankingColumn>,
    theme: Theme,
    selectedRowRef: React.RefObject<HTMLSpanElement>,
    i18n: I18n
  ) => React.ReactElement;
};

const getViewMode = (
  showAdditionalInfo: boolean,
  isSmallWidth: boolean
): TableViewMode => {
  if (isSmallWidth) {
    // 画面幅が狭いとき
    return TABLE_VIEW_MODE.SINGLE_COLUMN;
  } else {
    // 画面幅が広いときはユーザの指定に従う
    return showAdditionalInfo
      ? TABLE_VIEW_MODE.SINGLE_COLUMN_AND_INFO
      : TABLE_VIEW_MODE.MULTIPLE_COLUMNS;
  }
};

const createShowAdditionalInfoCheckbox = (
  showAdditionalInfo: boolean,
  setShowAdditionalInfo: React.Dispatch<React.SetStateAction<boolean>>,
  i18n: I18n
): JSX.Element => {
  return (
    <CheckboxLabel
      displayName={i18n.LABELS.localize(
        'show_additional_info',
        LABEL_CATEGORY.TABLE_COMMON
      )}
      checked={showAdditionalInfo}
      onChanged={(checked) => setShowAdditionalInfo(checked)}
    />
  );
};

const showAdditionalInfoCheckBox = <
  T extends {
    data?: Optional<RankingColumn>;
  }
>(
  columns: T[],
  tableViewMode: TableViewMode
): boolean => {
  return (
    tableViewMode !== TABLE_VIEW_MODE.SINGLE_COLUMN &&
    columns.some(
      (column) =>
        // additional info はエントリーリストや結果のテーブルの列にも存在し、
        // これらのテーブルでは表示したくない。
        // これらのテーブルの列には additional info はあっても rank は無いので、
        // rank が存在する場合も条件に加える
        column.data &&
        column.data.hasRank() &&
        column.data.hasActiveRank() &&
        column.data.hasAdditionalInfo()
    )
  );
};

export const TableBase: React.FC<RankingTableBaseProps> = ({
  isLoading,
  tableData,
  initialState,
  buttons,
  showAggregatorButtons,
  onStateChanged,
  onLinkDisplayed,
  onReadyToScroll,
  createTable,
  ...props
}) => {
  logger.debug(`Create TableBase element.  ${JSON.stringify(initialState)}`);
  const i18n = useI18n();
  const raceData = fetchRaceData();
  const activeOnlyContext = useActiveOnlyContext();
  const additionalInfoContext = useAdditionalInfoContext();
  const theme = useTheme();
  const isSmallWidth = useMediaQuery(theme.breakpoints.down('md'));
  const tableViewMode = getViewMode(additionalInfoContext.value, isSmallWidth);

  // 読み込んだデータを変換する
  const viewData = React.useMemo(
    () => createViewData(tableData, raceData, theme, onLinkDisplayed, i18n),
    [tableData]
  );

  // activeRankが存在する場合のみ切り替えボタンを表示する
  const isActiveOnlySupported = tableData.hasActiveRank();
  // activeRankが存在する場合のみtrue/falseになる。存在しないならfalse固定
  const isActiveOnly = isActiveOnlySupported && activeOnlyContext.value;

  const sortRule = React.useMemo(
    () => getInitialSortingRule(tableData, initialState),
    [tableData]
  );

  // テーブルの初期状態
  const tableOptions: TableOptions<RankingRow, RankingColumn> = React.useMemo(
    () =>
      createComponentOptions(
        viewData,
        initialState,
        sortRule,
        isActiveOnly,
        tableViewMode,
        onStateChanged
      ),
    [viewData]
  );

  // テーブルの生成
  const reactTable = useTable<RankingRow, RankingColumn>(
    tableOptions,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );
  // 下記の場合に実行する表示列と行の更新処理。初回と2ど更新が走るのは仕様
  // - データの更新されたとき
  // - ソート列が変更されたとき
  // - 画面幅が変更されたとき
  // - 現役フラグが変更されたとき
  const { allColumns, setGlobalFilter, state } = reactTable;
  const sortedColumnId = defined(state.sortBy[0]) ? state.sortBy[0].id : '';
  const sortedColumn = tableData.getColumn(sortedColumnId);
  React.useMemo(() => {
    const globalFilterValue = state.globalFilter as GlobalFilter;
    if (globalFilterValue.isActiveOnly !== isActiveOnly) {
      logger.debug(`Set global filter to ${String(isActiveOnly)}`);
      setGlobalFilter({ ...globalFilterValue, isActiveOnly });
    }

    const changedColumnIds: string[] = [];
    getColumnsVisibility(
      allColumns,
      sortedColumn?.id,
      tableViewMode,
      isActiveOnly
    ).forEach(([column, hidden]) => {
      if (column.isVisible === hidden) {
        column.toggleHidden(hidden);
        changedColumnIds.push(column.id);
      }
    });

    if (changedColumnIds.length > 0) {
      logger.debug(`Updated column visibility. ${String(changedColumnIds)}`);
    }
  }, [allColumns, sortedColumnId, tableViewMode, isActiveOnly]);

  // ソート列が変更されたときにAggregatorのボタンの選択状態を更新する
  // ボタンにはstateを持たせず親から選択状態を指示する。
  // GT500->GT300切り替え時に最小タイム差の列が無く、entry-sum_and_otherに
  // 強制更新したとき、子にstateがあると外から変更できない
  const aggregatorOptions = React.useMemo(
    () => createAggregatorOptions(tableData, sortedColumn, i18n),
    [sortedColumn]
  );

  useAsyncDebounce(() => {
    // logger.debug('Async');
  }, 100)();

  // テーブルの初期化が終わった状態
  //  - 列数が0以上→データがロードされている
  //  - ReactTableの初期化直後はsortByが設定されていない。そのときはfalseにする
  // sortByを初期値に指定しても、最初は空の配列が reactTable.state.sortBy に設定される。
  // ソートもされないので、意図的な挙動。ソート前とソート後でrowsが変わり、テーブルの描画が2回起きるため
  // 初回時を無視するためのフラグ
  // https://github.com/TanStack/table/blob/v7/src/plugin-hooks/useSortBy.js
  const isTableInitialized =
    viewData.columns.length > 0 &&
    state.sortBy.length === tableOptions.initialState?.sortBy?.length;

  // 初期化後で選択行が存在する場合、それを含むページに移動する
  const selectedRowIds = getSelectedRowIds(reactTable.state.selectedRowIds);
  const selectedRowRef = React.useRef<HTMLSpanElement>(null);
  React.useEffect(() => {
    if (isTableInitialized && defined(selectedRowIds[0])) {
      //
      logger.debug(
        `Change page index. selectedRowIds=${JSON.stringify(selectedRowIds)}`
      );
      const rowIndex = reactTable.rows
        .map((r) => r.id)
        .indexOf(selectedRowIds[0]);
      const pageIndex = Math.floor(rowIndex / reactTable.state.pageSize);
      if (
        pageIndex !== reactTable.state.pageIndex &&
        pageIndex >= 0 &&
        pageIndex < reactTable.pageCount
      ) {
        reactTable.gotoPage(pageIndex);
      }
    }
  }, [isTableInitialized, selectedRowIds.join(',')]);

  // 初期化後の最初の1回のみ、選択行が表示中のページに含まれていれば、そこへスクロールする
  const doScroll = React.useRef(true);
  React.useEffect(() => {
    if (
      doScroll.current &&
      isTableInitialized &&
      selectedRowsAllVisible(reactTable.page, selectedRowIds)
    ) {
      logger.debug(
        `Do scroll. Row=${String(defined(selectedRowRef?.current))} `
      );
      doScroll.current = false;
      selectedRowRef?.current?.scrollIntoView({ block: 'center' });
    }
  }, [
    doScroll.current &&
      isTableInitialized &&
      selectedRowsAllVisible(reactTable.page, selectedRowIds),
  ]);

  React.useEffect(() => {
    // ページ全体のスクロールのため通知する
    if (defined(onReadyToScroll)) {
      onReadyToScroll();
    }
  }, [doScroll.current && isTableInitialized]);

  // activeRankを含む列が存在する場合のみ表示する
  const showActiveOnlyButton = isTableInitialized && isActiveOnlySupported;
  const activeOnlyRenderCondition = [
    showActiveOnlyButton ? isActiveOnly : null,
  ];
  // 更新条件。表示されている列と行で見分ける
  const tableRenderCondition = [
    isTableInitialized ? tableOptions : null,
    isTableInitialized
      ? reactTable.visibleColumns.map((c) => c.id).join(',')
      : '',
    isTableInitialized ? reactTable.page.map((r) => r.id).join(',') : '',
  ];
  const tabsRenderCondition = [
    isTableInitialized ? tableOptions : null,
    isTableInitialized
      ? reactTable.visibleColumns.map((c) => c.id).join(',')
      : '',
    tableViewMode,
  ];

  const showAdditionalInfoCheckbox = showAdditionalInfoCheckBox(
    allColumns,
    tableViewMode
  );

  // Aggregator切り替え時、visibleColumnsは切り替え前、pageは切り替え後を示す

  // console.dir(tableRenderCondition);

  return (
    // apply the table props
    <Box
      {...props}
      sx={{
        display: 'flex', // flexレイアウトにしないと現役ボタンなどのY位置がずれる
        flexDirection: 'row', // 横方向に並べる
        flexWrap: 'wrap', // はみ出したら改行。メーカー別など
        ...props.sx,
      }}
    >
      <Backdrop
        open={isLoading && viewData.columns.length > 0}
        sx={{ zIndex: 'modal' }}
      />
      {React.useMemo(
        () =>
          showAggregatorButtons === false ? (
            <></>
          ) : (
            createAggregatorButton(allColumns, aggregatorOptions)
          ),
        [aggregatorOptions]
      )}
      {React.useMemo(
        () =>
          createActiveOnlyButton(showActiveOnlyButton, activeOnlyContext, i18n),
        activeOnlyRenderCondition
      )}
      {isTableInitialized ? buttons : null}
      {React.useMemo(
        () =>
          showAdditionalInfoCheckbox ? (
            createShowAdditionalInfoCheckbox(
              additionalInfoContext.value,
              additionalInfoContext.setValue,
              i18n
            )
          ) : (
            <></>
          ),
        [tableViewMode]
      )}
      {React.useMemo(
        () => createTabs(allColumns, tableViewMode),
        tabsRenderCondition
      )}
      {React.useMemo(
        () => createTable(reactTable, theme, selectedRowRef, i18n),
        tableRenderCondition
      )}
    </Box>
  );
};
