import React, { memo } from 'react';
import { Row } from 'react-table';

import {
  Backdrop,
  Box,
  SxProps,
  TableBody,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { fetchRaceData, RaceData } from '../util/DataUtil';
import { ActiveOnlyContextProps, useActiveOnlyContext } from './Context';
import { I18n, LABEL_CATEGORY, useI18n } from './I18n';
import { getLogger } from './Logger';
import {
  createCellElement,
  createRankElement,
  StyledTableCell,
  TableBaseProps,
} from './TableBase';
import {
  Column,
  TableInstance,
  TableOptions,
  useTable,
} from './ReactTableWrapper';
import { StyledTable } from './StyledComponents';
import { defined, returnIf } from './Util';
import {
  IndividualColumnId,
  IndividualRow,
  IndividualTableData,
  INDIVIDUAL_COLUMN_ID,
  RankingCell,
} from './ViewModel';

const logger = getLogger(import.meta.url);

type TableProps = TableBaseProps<IndividualTableData>;

const cell = (
  row: Row<IndividualRow>,
  columnId: IndividualColumnId,
  raceData: RaceData,
  activeOnlyContext: ActiveOnlyContextProps,
  i18n: I18n,
  theme: Theme,
  onLinkDisplayed: (link: string) => void
): React.ReactElement => {
  const cell = row.original.cell;
  if (columnId === INDIVIDUAL_COLUMN_ID.NAME) {
    const c = new RankingCell([row.original.name]);
    // 元のデータにracesがないのでtrueでも結果は変わらないはず
    return createCellElement(c, {
      raceData,
      i18n,
      hasRank: false,
      showRacesAsLink: true,
      canAbbreviateRoundInfo: false,
      onLinkDisplayed,
      onClick: () => {
        if (!defined(row.original.cell.activeRank)) {
          // 現役でないページの個人成績からランキングに飛ぶ場合は
          // 現役のみのフラグを解除し、表示時に画面内に表示させる
          activeOnlyContext.setValue(false);
        }
      },
    });
  } else if (columnId === INDIVIDUAL_COLUMN_ID.VALUE) {
    // 元のデータにracesがあるのでtrue
    return createCellElement(cell, {
      raceData,
      i18n,
      hasRank: true,
      showRacesAsLink: true,
      canAbbreviateRoundInfo: false,
      onLinkDisplayed,
    });
  } else if (columnId === INDIVIDUAL_COLUMN_ID.RANK) {
    return (
      returnIf(row.original.cell.rank, (r) =>
        createRankElement(r, false, theme, i18n)
      ) ?? <></>
    );
  } else if (columnId === INDIVIDUAL_COLUMN_ID.ACTIVE_RANK) {
    return (
      returnIf(row.original.cell.activeRank, (r) =>
        createRankElement(r, false, theme, i18n)
      ) ?? <></>
    );
  }
  return <></>;
};

interface ViewData {
  columns: Column<IndividualRow>[];
  rows: IndividualRow[];
}

const createViewData = (
  tableData: IndividualTableData,
  raceData: RaceData,
  activeOnlyContext: ActiveOnlyContextProps,
  theme: Theme,
  onLinkDisplayed: (link: string) => void,
  i18n: I18n
): ViewData[] => {
  logger.debug(`Create view model. rows=${tableData.rows.length}`);

  const rowGroups = tableData.rows.reduce((rowGroups, row) => {
    const category = row.id.getCategory();
    const rowGroup = rowGroups[category] ?? [];
    rowGroup.push(row);
    if (!(category in rowGroups)) {
      rowGroups[category] = rowGroup;
    }
    return rowGroups;
  }, {} as Record<string, IndividualRow[]>);

  return Object.entries(rowGroups).map(([category, rowGroup]) => {
    const columns: Column<IndividualRow>[] = tableData
      .getColumnIds()
      .map((id) => {
        return {
          Header:
            id === INDIVIDUAL_COLUMN_ID.NAME
              ? i18n.LABELS.localize(category, LABEL_CATEGORY.TABLE_CATEGORY)
              : i18n.LABELS.localize(id, LABEL_CATEGORY.PRODUCER),
          id,
          Cell: (props: { row: Row<IndividualRow> }) =>
            cell(
              props.row,
              id,
              raceData,
              activeOnlyContext,
              i18n,
              theme,
              onLinkDisplayed
            ),
        };
      });
    return {
      columns,
      rows: rowGroup,
    };
  });
};

const createComponentOptions = (
  viewData: ViewData
): TableOptions<IndividualRow> => {
  const { columns, rows } = viewData;
  logger.debug(`Create table options. rows=${rows.length}`);
  const tableOptions: TableOptions<IndividualRow> = {
    columns,
    data: rows,
    getRowId,
  };
  return tableOptions;
};

const ReactTable: React.FC<{
  reactTable: TableInstance<IndividualRow>;
  hasRank: boolean;
}> = ({ reactTable, hasRank }) => {
  const theme = useTheme();
  const fitTableWidthToWindow = useMediaQuery(theme.breakpoints.down('md'));

  const { headerGroups, prepareRow, rows } = reactTable;

  const sxNoRank = {
    // width=100%をリセットして幅をコンテンツに合わせる
    // スタイルはリセット
    container: {
      width: 'auto',
      //  border: 'none', boxShadow: 0
    },
    // 枠線を非表示にする
    // 各セルの右側に余白を入れて見やすくする
    // 各行の幅を狭める
    // 透明にして着色しない
    cell: {
      // border: 'none',
      // backgroundColor: 'transparent',
      pr: 6,
      // pb: 0,
      // pt: 0.5,
    },
  };
  const sxRankWide = {
    // width=100%をリセットして幅をコンテンツに合わせる
    // テーブルを縦横に並べたときのためmrとmbを設定
    // paddingだと外側にshadowがつくのでmarginを使用
    container: { mr: 4, mb: 4, width: 'auto' },
    cell: { pr: 6 },
  };
  const sxRankNarrow = {
    // width=100%を維持して幅をウインドウに合わせる
    // テーブルを縦に並べたときのためmbを設定
    // paddingだと外側にshadowがつくのでmarginを使用
    container: { mb: 4 },
    cell: {},
  };

  let sxValue: { container: SxProps; cell: SxProps };
  if (hasRank) {
    sxValue = fitTableWidthToWindow ? sxRankNarrow : sxRankWide;
  } else {
    sxValue = sxNoRank;
  }

  const tableHead = (
    <TableHead>
      {headerGroups.map((headerGroup) => {
        return (
          <TableRow key={headerGroup.getHeaderGroupProps()['key']}>
            {headerGroup.headers.map((column) => {
              return (
                <StyledTableCell key={column.id} header={true}>
                  {column.render('Header')}
                </StyledTableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableHead>
  );
  const tableBody = (
    <TableBody>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <TableRow key={row.id}>
            {row.cells.map((cell) => {
              const key = cell.getCellProps()['key'];
              return (
                <StyledTableCell key={key} sx={sxValue.cell}>
                  {cell.render('Cell')}
                </StyledTableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
  return (
    <StyledTable sx={sxValue.container}>
      {hasRank ? tableHead : <></>}
      {tableBody}
    </StyledTable>
  );
};

const getRowId = (row: IndividualRow): string => row.id.getFullId();

const SubTable: React.FC<{
  viewData: ViewData;
  hasRank: boolean;
}> = ({ viewData, hasRank }) => {
  const tableOptions: TableOptions<IndividualRow> = React.useMemo(
    () => createComponentOptions(viewData),
    [viewData]
  );
  const reactTable = useTable<IndividualRow>(tableOptions);
  return <ReactTable reactTable={reactTable} hasRank={hasRank} />;
};

const IndividualTable: React.FC<TableProps> = ({
  isLoading,
  tableData,
  buttons,
  onLinkDisplayed,
  onReadyToScroll,
}) => {
  const activeOnlyContext = useActiveOnlyContext();
  const theme = useTheme();
  const i18n = useI18n();
  const raceData = fetchRaceData();
  const hasRank = tableData.hasRank();
  const viewDataList = React.useMemo(
    () =>
      createViewData(
        tableData,
        raceData,
        activeOnlyContext,
        theme,
        onLinkDisplayed ?? (() => 0),
        i18n
      ),
    [tableData]
  );

  React.useEffect(() => {
    // ページ全体のスクロールのため通知する
    if (defined(onReadyToScroll)) {
      onReadyToScroll();
    }
  }, []);

  return (
    <Box
      sx={{
        display: 'flex', // 横幅はコンテンツに合わせる
        flexDirection: 'row', // 横方向に並べる
        flexWrap: 'wrap', // はみ出したら改行
        width: '100%', // 親の幅と合わせる
      }}
    >
      <Backdrop open={isLoading} sx={{ zIndex: 'modal' }} />
      <Box
        sx={{
          display: 'flex', // 各ボタンの横幅はコンテンツに合わせる
          flexWrap: 'wrap', // ボタンが画面をはみ出したら次の行に表示する
          width: '100%', // 右側にSubTableが来ないようにする
        }}
      >
        {buttons}
      </Box>
      {React.useMemo(
        () => (
          <>
            {viewDataList.map((viewData, index) => (
              <SubTable key={index} viewData={viewData} hasRank={hasRank} />
            ))}
          </>
        ),
        [viewDataList]
      )}
    </Box>
  );
};

export default memo(IndividualTable);
