import {
  Check,
  FilterList,
  Forward,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Fab,
  FormControlLabel,
  Table,
  TableProps,
  Theme,
  Typography,
  TypographyProps,
  useScrollTrigger,
  useTheme,
  Zoom,
} from '@mui/material';
import * as React from 'react';

import IndividualTable from './IndividualTable';
import OptionalOptionButton, {
  OptionalOptionButtonProps,
} from './OptionalOptionButton';
import RequiredOptionButton, {
  RequiredOptionButtonProps,
} from './RequiredOptionButton';
import { getIconSize, Optional } from './Util';
import { createIndividualTableData } from './ViewModel';

export const StyledTable: React.FC<TableProps> = (props) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: 'flex', // StyledTableのマージンを含む横幅を要素内に収める
        overflowX: 'auto', // pageIndexは横スクロールさせない
        width: '100%', // 画面幅が640pxより少し小さい場合、100%を外すと表が横幅に合わない
      }}
    >
      <Table
        size={'small'}
        {...props}
        sx={{
          height: 'fit-content',
          boxShadow: 2,
          borderWidth: 0,
          borderColor: theme.palette.grey[300], //行間の色と同じ
          borderStyle: 'solid',
          m: 0.5, //マージンがないとboxShadowが表示されない
          ...props.sx,
        }}
      />
    </div>
  );
};

const requiredOptionTopButtonSx = {
  typography: 'body1',
  color: 'text.secondary',
  fontWeight: 900,
};

const requiredOptionTopButtonSelectedSx = {
  ...requiredOptionTopButtonSx,
  color: 'text.primary',
  textDecoration: 'underline',
  textUnderlineOffset: 4,
};

// React.FC<T>のTをgenerics付きの型 (Optional<V>) にできない
// そのため、引数側ではなく代入する関数側で型を決める
export const RequiredOptionTopTypography = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  D extends React.ElementType<any> = 'span',
  P = unknown
>(
  props: TypographyProps<D, P>
): React.ReactElement => (
  <Typography
    {...props}
    sx={{ ...requiredOptionTopButtonSelectedSx, ...props.sx }}
  />
);

export const RequiredOptionTopButton = (
  props: RequiredOptionButtonProps
): React.ReactElement => (
  <RequiredOptionButton
    {...props}
    sx={{
      ...props.sx,
      flexBasis: '100%', // 画面幅に寄らず、常に横幅100%で表示する
    }}
    buttonProps={{
      sx: requiredOptionTopButtonSx,
      variant: 'text',
    }}
    buttonPropsSelected={{
      sx: requiredOptionTopButtonSelectedSx,
    }}
  />
);

export const RequiredOptionSecondButton = (
  props: RequiredOptionButtonProps
): React.ReactElement => (
  <Box
    sx={{
      display: 'flex', // 横スクロールのためにdisplayとoverflowXが必要
      overflowX: 'auto', // はみ出した場合のみスクロールバーを表示
    }}
  >
    <RequiredOptionButton
      {...props}
      sx={{
        ...props.sx,
        m: 1,
        flexShrink: 0, // 画面幅が狭まっても各ボタンの横幅を維持する。はみ出したらBox要素によりスクロール可能になる
      }}
      buttonProps={{
        variant: 'outlined',
      }}
      buttonPropsSelected={{
        variant: 'contained',
      }}
    />
  </Box>
);

export const ActiveOnlyButton = (
  props: RequiredOptionButtonProps
): React.ReactElement => (
  <RequiredOptionButton
    {...props}
    sx={{ ...props.sx, m: 1 }}
    canUnselect={true}
    buttonProps={{
      startIcon: <Check sx={{ visibility: 'hidden' }} />,
      variant: 'outlined',
    }}
    buttonPropsSelected={{
      startIcon: <Check />,
      variant: 'contained',
    }}
  />
);

export const FilterButton = (
  props: OptionalOptionButtonProps
): React.ReactElement => (
  <OptionalOptionButton {...props} sx={{ m: 1 }} startIcon={<FilterList />} />
);

interface CheckboxLabelProps {
  displayName: string;
  checked: boolean;
  onChanged: (checked: boolean) => void;
}

export const CheckboxLabel = ({
  displayName,
  checked,
  onChanged,
}: CheckboxLabelProps): React.ReactElement => (
  // 達成レースをテーブル内に表示するか切り替えるチェックボックス
  <FormControlLabel
    sx={{ m: 0 }}
    control={
      <Checkbox
        defaultChecked={checked}
        onChange={(e) => {
          onChanged(e.target.checked);
        }}
        size="small"
        color="primary"
      ></Checkbox>
    }
    label={<Typography variant="body2">{displayName}</Typography>}
  />
);

type RankDiffIconProps = JSX.IntrinsicElements['span'] & {
  diffValue: Optional<string | number>;
  theme: Theme;
};

export const RankDiffIcon = ({
  diffValue,
  theme,
  ...props
}: RankDiffIconProps): React.ReactElement => {
  let rankDiffElement = <></>;
  let num = NaN;
  if (typeof diffValue === 'number') {
    num = diffValue;
  } else if (typeof diffValue === 'string') {
    num = parseInt(diffValue);
  }
  if (num !== 0 && !isNaN(num)) {
    const color =
      num > 0 ? theme.palette.secondary.main : theme.palette.primary.main;
    // 右向きアイコンを上または下に回転させる
    const transform = `rotate(${num > 0 ? '' : '-'}90deg)`;
    const absNum = num > 0 ? num : -num;

    const iconSize = getIconSize(theme.typography.body3.fontSize);
    rankDiffElement = (
      <>
        <Forward
          key="icon"
          style={{ transform }}
          sx={{
            color,
            fontSize: iconSize,
          }}
        />
        <Typography key="label" component="span" variant="body3" sx={{ color }}>
          {absNum}
        </Typography>
      </>
    );
  }

  return (
    // display: flex で囲むことで改行回避
    <span {...props} style={{ display: 'flex' }}>
      <span key="children">{props.children}</span>
      {rankDiffElement}
    </span>
  );
};

type AreaProps = {
  id?: string;
  title: string;
  children?: React.ReactNode;
  breadCrumps?: React.ReactNode;
};

// H1
export const PageArea: React.FC<AreaProps> = ({
  title,
  children,
  breadCrumps,
}) => {
  const theme = useTheme();
  return (
    <>
      <Box sx={{ flexBasis: '100%', m: 1 }}>{breadCrumps}</Box>
      <Typography
        variant={'h1'}
        sx={{
          flexBasis: '100%',
          m: 1,
          mb: 2,
          py: 0.5,
          borderBottomColor: theme.palette.grey[600],
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
        }}
      >
        {title}
      </Typography>
      {children}
      <Box sx={{ flexBasis: '100%', m: 3 }} />
    </>
  );
};

// H2
export const TableArea = React.forwardRef<HTMLDivElement, AreaProps>(
  ({ id, title, children }, ref): React.ReactElement => {
    const theme = useTheme();
    return (
      <>
        <Box
          id={id}
          ref={ref}
          style={{
            // ヘッダーに隠れないようその高さ分だけ下にずらす
            scrollMarginTop: '72px',
          }}
          sx={{
            display: 'flex',
            flexBasis: '100%',
            m: 1,
            px: 1,
            borderLeftColor: theme.palette.primary.main,
            borderLeftWidth: '6px',
            borderLeftStyle: 'solid',
          }}
        >
          <Typography variant={'h2'}>{title}</Typography>
        </Box>
        {children}
        <Box sx={{ flexBasis: '100%', m: 2 }} />
      </>
    );
  }
);
TableArea.displayName = 'TableArea';

// H3
export const H3: React.FC<AreaProps> = ({ title, children }) => {
  return (
    <>
      <Typography
        variant={'h3'}
        sx={{
          flexBasis: '100%',
          m: 1,
          px: 1,
        }}
      >
        {title}
      </Typography>
      {children}
      <Box sx={{ flexBasis: '100%', m: 1 }} />
    </>
  );
};

export const ScrollTopFab: React.FC = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });
  const scrollToTop = React.useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <Zoom in={trigger}>
      <Box
        role="presentation"
        sx={{ position: 'fixed', bottom: 32, right: 32, zIndex: 1 }}
      >
        <Fab onClick={scrollToTop} color="primary" size="small">
          <KeyboardArrowUp />
        </Fab>
      </Box>
    </Zoom>
  );
};

const PreviewStyledComponents = (): React.ReactElement => {
  const theme = useTheme();
  const [filterButtonState, setFilterButtonState] = React.useState<
    Record<string, string>
  >({});
  const profileData = createIndividualTableData({
    columns: [{ title: 'name' }, { title: 'value' }],
    rows: [
      {
        id: 'a',
        cells: [
          {
            values: [{ value: 'key_birthday-concat_set', continuing: false }],
          },
          { values: [{ value: '1999/12/31', continuing: false }] },
        ],
      },
      {
        id: 'b',
        cells: [
          {
            values: [{ value: 'key_circuit-concat_set', continuing: false }],
          },
          { values: [{ value: '鈴鹿サーキット', continuing: false }] },
        ],
      },
    ],
  });

  const profileData2 = createIndividualTableData({
    columns: [{ title: 'name' }, { title: 'value' }],
    rows: [
      {
        id: 'a',
        cells: [
          {
            values: [{ value: 'key_birthday-concat_set', continuing: false }],
          },
          {
            values: [
              {
                value:
                  '1999/12/31 too long sentence this is. Maybe it happens if circuit name or race name is too long.',
                continuing: false,
              },
            ],
          },
        ],
      },
    ],
  });
  return (
    <>
      <Box
        sx={{ display: 'inline-flex', flexDirection: 'row', flexWrap: 'wrap' }}
      >
        <PageArea title="ドライバーランキング">
          <TableArea title="プロフィール">
            <IndividualTable tableData={profileData} isLoading={false} />
          </TableArea>
          <TableArea title="プロフィール">
            <IndividualTable tableData={profileData2} isLoading={false} />
          </TableArea>
          <TableArea title="経歴">
            <RequiredOptionTopButton
              values={[
                { id: 'GT300', value: 'GT300', available: true },
                { id: 'GT500', value: 'GT500', available: true },
              ]}
              selectedId={'GT500'}
            />
            <RequiredOptionSecondButton
              values={[
                { id: 'a', value: '通算', available: true },
                { id: 'b', value: '連続', available: true },
                { id: 'c', value: '達成率', available: true },
                { id: 'd', value: 'その他', available: true },
              ]}
              selectedId={'a'}
            />
            <ActiveOnlyButton
              values={[{ id: 'a', value: '現役のみ', available: true }]}
              selectedId={''}
            />
            <FilterButton
              defaultName={'絞り込み'}
              values={[
                {
                  id: 'a',
                  displayName: 'タイヤ',
                  values: [
                    { id: 'a1', displayName: 'ヨコハマ', available: true },
                    { id: 'a2', displayName: 'ブリヂストン', available: true },
                    { id: 'a3', displayName: 'ダンロップ', available: false },
                  ],
                },
                {
                  id: 'b',
                  displayName: 'メーカー',
                  values: [
                    { id: 'b1', displayName: 'トヨタ', available: true },
                    { id: 'b2', displayName: 'ホンダ', available: true },
                    { id: 'b3', displayName: 'マクラーレン', available: false },
                  ],
                },
              ]}
              selectedId={filterButtonState}
              onChanged={(s) => setFilterButtonState(s)}
            />
            <CheckboxLabel
              displayName="達成レースをテーブル内に表示"
              checked={true}
              onChanged={() => {
                // do nothing
              }}
            />
          </TableArea>
        </PageArea>
      </Box>
      {[...new Array(2).keys()].map((num) => {
        return (
          <span key={num}>
            <RankDiffIcon diffValue={num - 1} theme={theme} />
          </span>
        );
      })}
    </>
  );
};

export default PreviewStyledComponents;
