import { Box, Breadcrumbs, Drawer, Typography, useTheme } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import {
  PageState,
  TableSectionState,
  createLinkElement,
  createOptions,
  fetchJsonData,
  fetchRaceData,
  usePageData,
} from '../util/DataUtil';
import { HeaderData } from './Header';
import { I18n, useI18n } from './I18n';
import { getLogger } from './Logger';
import { PathData } from './PathModel';
import { PageArea, TableArea } from './StyledComponents';
import TableSection from './TableSection';
import { defined } from './Util';
import { createPageState } from './ViewModel';
import { UrlTable } from './styled/UrlTable';

const logger = getLogger(import.meta.url);

interface PageProp {
  filePath: string;
}

const getHistoryState = (): PageState => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    (history.state?.state as PageState) ?? createHistoryState()
  );
};

const setHistoryState = (state: PageState): void => {
  history.replaceState({ state }, '');
};

const createHistoryState = (): PageState => {
  const location = window.location;
  return (
    createPageState(
      location.hash.replace('#', ''),
      location.search.replace('?', '')
    ) ?? {
      initialTable: undefined,
      tableStates: {},
    }
  );
};

const PageBreadcrumbs: React.FC<PageProp> = ({ filePath }) => {
  const i18n = useI18n();
  const pathData = new PathData(filePath);
  const parentPath = '/' + filePath.split('/').slice(0, -1).join('/');
  const parentName = new PathData(parentPath).localizeDirName(i18n);

  if (parentName.displayName.length > 0) {
    // TODO json読み込みはまとめる
    const headerData = fetchJsonData<HeaderData>(
      '/layouts/header.json',
      (v) => v
    );
    const headerItemIds = Object.values(headerData.buttons).reduce<string[]>(
      (ids, items) =>
        typeof items === 'string'
          ? ids
          : [...ids, ...items.map((item) => item.displayId)],
      []
    );

    // リンク先のページがヘッダーから参照されている場合のみ、リンクする
    const dirName = pathData.localizeDirName(i18n);
    const element = headerItemIds.includes(
      Object.keys(pathData.dirParameters)[0] ?? ''
    ) ? (
      <Link to={{ pathname: parentPath }}>{parentName.displayName}</Link>
    ) : (
      <Typography>{parentName.displayName}</Typography>
    );

    return (
      <Breadcrumbs sx={{ pb: 2 }}>
        {element}
        <Typography>{dirName.displayName}</Typography>
      </Breadcrumbs>
    );
  } else {
    return <></>;
  }
};

const createPageTitle = (filePath: string, i18n: I18n): string => {
  let title = new PathData(filePath).localizeDirName(i18n).displayName;
  const parentPath = '/' + filePath.split('/').slice(0, -1).join('/');
  const parentName = new PathData(parentPath).localizeDirName(i18n).displayName;
  if (parentName.length > 0) {
    title = `${parentName} / ${title}`;
  }
  return i18n.LABELS.localize('page_title', undefined, { title });
};

const Page: React.FC<PageProp> = ({ filePath }) => {
  const data = usePageData(filePath);
  const raceData = fetchRaceData();
  const theme = useTheme();
  const i18n = useI18n();
  const [footer, setFooter] = useState<React.ReactElement | null>(null);
  const [description, setDescription] = useState<string>('');

  // リンク元が設定したstateよりテーブル名と列名を取得
  const pageState: PageState = getHistoryState();
  logger.debug(`START ${filePath}`);

  const onLinkDisplayed = useCallback((link: string) => {
    const labelId = isNaN(parseInt(link.slice(0, 4)))
      ? 'page_archived_race'
      : 'page_archived_year';
    const footer = (
      <>
        <Box>
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
            {i18n.LABELS.localize(labelId)}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
          {createLinkElement(link, raceData, i18n)}
        </Typography>
      </>
    );
    setFooter(footer);
  }, []);

  const onFooterClicked = useCallback(() => {
    setFooter(null);
  }, []);

  const onTableSectionStateChanged = useCallback(
    (tableId: string, tableSectionState: TableSectionState) => {
      const previousState = getHistoryState();
      const newState: PageState = {
        ...previousState,
        tableStates: {
          ...previousState.tableStates,
          [tableId]: tableSectionState,
        },
      };
      logger.debug(`Change state to ${JSON.stringify(newState)}`);
      setHistoryState(newState);
    },
    []
  );
  const options = createOptions(data);
  const targetTableId = pageState.initialTable ?? '';
  // スクロール対象のテーブルより上に存在するテーブルID一覧
  const tableIds = options.map((o) => o.tableId);
  const tableRefs = React.useRef<Record<string, HTMLSpanElement>>({});
  const [loadedTablesState, setLoadedTablesState] = React.useState(
    Object.fromEntries(tableIds.map((id) => [id, false]))
  );
  React.useEffect(() => {
    // スクロール対象のテーブルより上のテーブルが全てロードされたらスクロールする
    // スクロール後にデータ読み込みにより縦位置がずれるのを防ぐ
    const readyToScroll = Object.entries(loadedTablesState)
      .filter((v, index) => index < tableIds.indexOf(targetTableId))
      .every(([, value]) => value);
    if (readyToScroll) {
      const table = tableRefs.current[targetTableId];
      if (defined(table)) {
        logger.debug(`Do scroll. Table=${targetTableId}`);
        table.scrollIntoView();
      }
      // スクロール位置をリセットする。ブラウザバック時にスクロールさせないため
      setHistoryState({ ...getHistoryState(), initialTable: undefined });
    }
  }, [loadedTablesState]);

  React.useEffect(() => {
    // ロード済みテーブルの内容の文字列を取得
    const loadedTableTexts = Object.entries(loadedTablesState)
      .filter(([, value]) => value)
      .map(([id]) => id)
      .map((id) => tableRefs.current[id]?.innerText.replace(/\s+/g, ' '));
    setDescription(loadedTableTexts.join(' ').substring(0, 256));
  }, [loadedTablesState]);

  const pathData = new PathData(filePath);
  const title = pathData.localizeDirName(i18n).displayName;

  const tables: JSX.Element[] = React.useMemo(
    () =>
      options.map((tableOption) => {
        const { tableId } = tableOption;
        const tableSection = (
          <TableSection
            sx={{ pb: 3 }}
            filePath={filePath}
            table={tableOption}
            initialState={
              pageState.tableStates[tableId] ?? {
                optionsState: {},
                tableState: {},
              }
            }
            onStateChanged={(newState) =>
              onTableSectionStateChanged(tableId, newState)
            }
            onLinkDisplayed={onLinkDisplayed}
            onReadyToScroll={() =>
              setLoadedTablesState((state) => ({
                ...state,
                [tableId]: true,
              }))
            }
          />
        );
        return (
          <div key={tableId}>
            {options.length > 1 ? (
              // ref は HTML タグにしか付与できないのでspanで囲う
              <span
                ref={(element) => {
                  if (defined(element)) {
                    tableRefs.current[tableId] = element;
                  }
                }}
              >
                <TableArea
                  title={i18n.LABELS.localize(tableId, undefined, { title })}
                >
                  {tableSection}
                </TableArea>
              </span>
            ) : (
              tableSection
            )}
          </div>
        );
      }),
    []
  );

  const urlElement = (
    <UrlTable items={pathData.getUrlsInfo(i18n)} theme={theme} />
  );

  // TODO: 下のローディング表示
  return (
    <>
      <Helmet>
        <title>{createPageTitle(filePath, i18n)}</title>
        <meta name="description" content={description} />
      </Helmet>
      <PageArea
        title={title}
        breadCrumps={<PageBreadcrumbs filePath={filePath} />}
      >
        {urlElement}
        {tables}
        <Drawer
          anchor={'bottom'}
          onClose={onFooterClicked}
          open={defined(footer)}
          PaperProps={{ sx: { maxHeight: '50%', p: 1 } }}
        >
          {footer}
        </Drawer>
      </PageArea>
    </>
  );
};

export default Page;
