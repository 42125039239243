import React, { memo } from 'react';
import { HeaderGroup } from 'react-table';

import {
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
} from '@mui/material';
import { getLogger } from './Logger';
import { TableInstance } from './ReactTableWrapper';
import { StyledTable } from './StyledComponents';
import { StyledTableCell, RankingTableBaseProps, TableBase } from './TableBase';
import { RankingColumn, RankingRow } from './ViewModel';
import { LABEL_CATEGORY, I18n } from './I18n';

const logger = getLogger(import.meta.url);

const createTable = (
  reactTable: TableInstance<RankingRow, RankingColumn>,
  theme: Theme,
  selectedRowRef: React.RefObject<HTMLSpanElement>,
  i18n: I18n
): React.ReactElement => {
  const {
    columns,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageCount,
    gotoPage,
    state,
    setSortBy,
  } = reactTable;
  logger.debug(
    `Create react table element. sortBy=${String(state.sortBy[0]?.id)} rows=${
      rows.length
    } pageIndex=${state.pageIndex} pageCount=${pageCount}`
  );

  // ソート可能な列が複数存在している場合のみ、セルの背景色を有効にする
  // 呼び出し元から TableViewMode を貰って判断してもよいが、現状は以下の方法で
  // 判定できるため不要
  const multipleColumnsCanSort =
    columns.filter((column) => column.isVisible && column.canSort).length > 1;

  // viewDataの切り替え時、1つ前のpageIndexが引き継がれてしまう
  // その場合はpageCountを超えるため、修正する
  const pageIndex = state.pageIndex < pageCount ? state.pageIndex : 0;

  const updateSortBy = (
    e: React.SyntheticEvent,
    column: HeaderGroup<RankingRow>
  ) => {
    e.persist(); // オリジナルのコードにあった
    // toggleSortBy を使用した場合、状態が 昇順→降順→解除と変わってしまう
    const isCurrentDesc = column.isSortedDesc ?? true;
    const desc = column.isSorted ? !isCurrentDesc : false;
    setSortBy([{ id: column.id, desc }]);
  };

  // 幅を最小にする。改行して中を折り返す。tableの幅が100%で列幅が十分に取られる前提。
  const cellStyle = {
    width: '0',
  } as React.CSSProperties;

  const tableBody =
    rows.length > 0 ? (
      <TableBody>
        {page.map((row) => {
          prepareRow(row);
          return (
            <TableRow key={row.id} selected={row.isSelected}>
              {row.cells.map((cell, index) => {
                const key = cell.getCellProps()['key'];
                return (
                  <StyledTableCell
                    key={key}
                    selected={multipleColumnsCanSort && cell.column.isSorted}
                    theme={theme}
                    style={cellStyle}
                  >
                    <span
                      ref={
                        row.isSelected && index === 0 ? selectedRowRef : null
                      }
                    >
                      {cell.render('Cell')}
                    </span>
                  </StyledTableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    ) : (
      <TableBody>
        <TableRow>
          <StyledTableCell
            theme={theme}
            style={cellStyle}
            colSpan={headerGroups[0]?.headers.length}
            align="center"
          >
            {i18n.LABELS.localize('table_no_item', LABEL_CATEGORY.TABLE_COMMON)}
          </StyledTableCell>
        </TableRow>
      </TableBody>
    );

  return (
    <>
      <StyledTable>
        <TableHead style={{ verticalAlign: 'top' }}>
          {headerGroups.map((headerGroup) => {
            return (
              <TableRow key={headerGroup.getHeaderGroupProps()['key']}>
                {headerGroup.headers.map((column) => {
                  // 上向き矢印がasc, 下向き矢印がdesc
                  return (
                    <StyledTableCell
                      key={column.id}
                      header={true}
                      selected={multipleColumnsCanSort && column.isSorted}
                      theme={theme}
                      style={cellStyle}
                    >
                      {column.render('Header')}
                      <TableSortLabel
                        hideSortIcon={!column.canSort}
                        active={column.isSorted}
                        direction={column.isSortedDesc ?? true ? 'desc' : 'asc'}
                        onClick={(e) => updateSortBy(e, column)}
                      />
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        {tableBody}
      </StyledTable>
      {rows.length > state.pageSize ? (
        <Table>
          <TableBody>
            <TableRow>
              <TablePagination
                count={rows.length}
                page={pageIndex}
                rowsPerPage={state.pageSize}
                rowsPerPageOptions={[state.pageSize]}
                showFirstButton={true}
                showLastButton={true}
                onPageChange={(_e, newIndex: number) => gotoPage(newIndex)}
              />
            </TableRow>
          </TableBody>
        </Table>
      ) : null}
    </>
  );
};

type TableProps = Omit<RankingTableBaseProps, 'createTable'>;

const RankingTable: React.FC<TableProps> = (props) => (
  <TableBase {...props} createTable={createTable} />
);
export default memo(RankingTable);
