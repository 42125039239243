/* eslint-disable @typescript-eslint/ban-types */
import {
  Column as COL,
  ColumnInstance as CI,
  PluginHook,
  TableInstance as TI,
  TableOptions as TO,
  useTable as useReactTable,
  UseTableInstanceProps as UT,
  SortingRule as SR,
  TableState as TS,
} from 'react-table';
import { Optional } from './Util';

export type Column<D extends object = {}, C extends object = {}> = COL<D> & {
  data?: Optional<C>;
};

export type SortingRule<
  D extends object = {},
  C extends object = {}
> = SR<D> & {
  data: C;
};

export type TableState<D extends object = {}, C extends object = {}> = TS<D> & {
  sortBy: Array<SortingRule<D, C>>;
};

export type TableOptions<
  D extends object = {},
  C extends object = {}
> = TO<D> & {
  columns: ReadonlyArray<Column<D, C>>;
};

export type ColumnInstance<
  D extends object = {},
  C extends object = {}
> = CI<D> & {
  data?: Optional<C>;
};

export type UseTableInstanceProps<
  D extends object = {},
  C extends object = {}
> = UT<D> & {
  columns: Array<ColumnInstance<D, C>>;
  allColumns: Array<ColumnInstance<D, C>>;
  headers: Array<ColumnInstance<D, C>>;
  flatHeaders: Array<ColumnInstance<D, C>>;
};

export type TableInstance<
  D extends object = {},
  C extends object = {}
> = TI<D> & UseTableInstanceProps<D, C>;

type UseTableType = <D extends object = {}, C extends object = {}>(
  options: TableOptions<D, C>,
  ...plugins: Array<PluginHook<D>>
) => TableInstance<D, C>;

export const useTable: UseTableType = useReactTable as unknown as UseTableType;
