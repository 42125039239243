import { fetchRaceData } from '../util/DataUtil';
import { getLocalizeKeyNames, I18n } from './I18n';
import { defined, mergeRecords, Optional } from './Util';

export interface ValueFormatter {
  format: (value: string, i18n: I18n) => string;
  kana: (value: string, i18n: I18n) => { kana?: string };
  asNumber: (value: string) => number;
  supportsKana: () => boolean;
}

class FormatError extends Error {}

const check: (value: boolean, message?: string) => asserts value is true = (
  value: boolean,
  message = ''
): void => {
  if (!value) {
    throw new FormatError(message);
  }
};

export const getFormatter = (id: string): Optional<ValueFormatter> => {
  return valueFormatterDict[id];
};

export const formatOrLocalize = (
  key: string,
  value: string,
  i18n: I18n
): { displayName: string; kana: string } => {
  const formatter = getFormatter(key);
  return {
    displayName: formatter?.format(value, i18n) ?? value,
    kana: formatter?.kana(value, i18n).kana ?? value,
  };
};

const createDefaultFormatter = (
  format: (value: string, i18n: I18n) => string,
  {
    kana,
    supportsKana,
    asNumber,
  }: {
    kana?: (value: string, i18n: I18n) => { kana?: string };
    supportsKana?: () => boolean;
    asNumber?: (value: string) => number;
  } = {}
): ValueFormatter => {
  kana = kana ?? (() => ({}));
  supportsKana = supportsKana ?? (() => false);
  asNumber = asNumber ?? ((value) => Number(value));

  return {
    format,
    kana,
    supportsKana,
    asNumber,
  };
};

const timesFormatter: ValueFormatter = createDefaultFormatter((value) => {
  check(Number.isInteger(Number(value)));
  return value + '回';
});

const contTimesFormatter: ValueFormatter = createDefaultFormatter((value) => {
  const num = Number(value);
  check(Number.isInteger(num));
  const postfix = num === 1 ? '回' : '回連続';
  return `${String(num)}${postfix}`;
});

const rankFormatter: ValueFormatter = createDefaultFormatter((value) => {
  const num = Number(value);
  check(Number.isInteger(num));
  // レース結果順位はリタイアの場合-1となる。その場合はハイフンを表示する
  return num > 0 ? `${num}位` : '-';
});

const ratioFormatter: ValueFormatter = {
  format: (value) => {
    const num = Number(value);
    return `${Math.round(num * 10000) / 100}%`;
  },
  kana: () => ({}),
  supportsKana: () => false,
  asNumber: (value) => Math.round(Number(value) * 10000) / 100,
};

const rankRatioFormatter: ValueFormatter = {
  format: (value) => {
    const num = Number(value);
    check(defined(num));
    return `${Math.round(num * 100) / 100}位`;
  },
  kana: () => ({}),
  supportsKana: () => false,
  asNumber: (value) => Math.round(Number(value) * 100) / 100,
};

const translationFormatterCreator = (category: string): ValueFormatter => {
  return createDefaultFormatter(
    (value, i18n) => i18n.DATA.localize(value, category),
    {
      kana: (value, i18n) => {
        // フリガナが存在しない場合はその値を返す 値とフリガナが同じ場合があるため
        const kana = i18n.DATA_KANA.localize(value, category);
        return defined(kana) ? { kana } : {};
      },
      supportsKana: () => true,
    }
  );
};

const ageFormatter: ValueFormatter = createDefaultFormatter((value) => {
  const num = Number(value);
  check(defined(num));
  const year = Math.trunc(num);
  const date = Math.round((num - year) * 1000);
  const yearString = year > 0 ? `${String(year)}歳と` : '';
  return `${yearString}${String(date)}日`;
});

class Diff {
  private lap: Optional<number>;
  private time: Optional<{
    hour: string;
    minute: string;
    second: string;
  }>;

  constructor(value: string) {
    let m = /^lap([0-9]{3})$/.exec(value);
    if (defined(m) && defined(m[1])) {
      this.lap = Number(m[1]);
    } else {
      m = /^([0-9]{2}):([0-9]{2}):([0-9]+.[0-9]+)$/.exec(value);
      if (defined(m) && defined(m[3])) {
        this.time = {
          hour: m[1] ?? '0',
          minute: m[2] ?? '0',
          second: m[3],
        };
      } else {
        check(false, `"${String(value)}"`);
      }
    }
  }

  getString(): string {
    if (defined(this.lap)) {
      return `${String(this.lap)}${this.lap > 1 ? 'laps' : 'lap'}`;
    } else if (defined(this.time)) {
      let timeString = this.time.second;
      const minute = parseInt(this.time.minute);
      if (minute !== 0) {
        timeString = `${String(minute)}:${timeString}`;
      }
      const hour = parseInt(this.time.hour);
      if (hour !== 0) {
        timeString = `${String(hour)}:${timeString}`;
      }
      return timeString;
    } else {
      return '';
    }
  }
}

const diffFormatter: ValueFormatter = createDefaultFormatter((value) => {
  return new Diff(value).getString();
});

const yearFormatter: ValueFormatter = createDefaultFormatter((value) => {
  const title = parseInt(value) < 2005 ? 'JGTC' : 'SUPER GT';
  return `${value}年 ${title}`;
});

const countCircuitFormatter: ValueFormatter = createDefaultFormatter(
  (value) => {
    return `${value}個`;
  }
);

const raceIdFormatter: ValueFormatter = (() => {
  const format = (value: string, i18n: I18n): string => {
    const raceData = fetchRaceData();
    const race = raceData[value];
    if (defined(race)) {
      return `${yearFormatter.format(String(race.year), i18n)} ラウンド ${
        race.round
      }`;
    } else {
      return value;
    }
  };
  return createDefaultFormatter(format, {
    kana: (value, i18n) => ({ kana: format(value, i18n) }),
    supportsKana: () => true,
  });
})();

// diff の値が数値で来るので asNumber はデフォルト実装を使用
const dateFormatter: ValueFormatter = createDefaultFormatter((value) => value);

const rawFormatter: ValueFormatter = createDefaultFormatter((value) => value, {
  asNumber: () => NaN,
});

const rawIntFormatter: ValueFormatter = createDefaultFormatter(
  (value) => {
    check(Number.isInteger(Number(value)));
    return value;
  },
  { asNumber: () => NaN }
);

const rawPointFormatter: ValueFormatter = createDefaultFormatter((value) => {
  check(Number.isInteger(Number(value) * 2));
  return Number(value).toString();
});

// gt300 -> GT300に変換する
const raceCategoryFormatter: ValueFormatter = createDefaultFormatter((value) =>
  value.toUpperCase()
);

const valueFormatterDict: { [name: string]: Optional<ValueFormatter> } = {
  // ランク
  rank: rankFormatter,
  // 平均順位
  position_avg: rankRatioFormatter,
  // レース順位
  position: rankFormatter,

  // 達成率
  avg: ratioFormatter,
  // 連続達成数
  cont: contTimesFormatter,
  // ランキングの項目
  champion: timesFormatter,
  entry: timesFormatter,
  fastest: timesFormatter,
  finished: timesFormatter,
  perfect: timesFormatter,
  podium: timesFormatter,
  pole: timesFormatter,
  pole_to_win: timesFormatter,
  win: timesFormatter,
  win_circuit: timesFormatter,
  count_circuit: countCircuitFormatter,

  win_age: ageFormatter,
  win_diff: diffFormatter,

  finished_jump_up: rankFormatter,
  win_jump_up: rankFormatter,

  dominant_2: timesFormatter,
  dominant_3: timesFormatter,
  dominant_4: timesFormatter,
  dominant_5: timesFormatter,
  dominant_6: timesFormatter,

  // 同時達成
  entry_double: timesFormatter,
  finished_double: timesFormatter,
  win_double: timesFormatter,
  podium_double: timesFormatter,
  dominant_2_double: timesFormatter,

  // 達成記録
  age: ageFormatter,
  race_date: dateFormatter,
  race_count: timesFormatter,

  // 翻訳対象
  ...mergeRecords(
    getLocalizeKeyNames().map((k) => ({
      [k]: translationFormatterCreator(k),
    }))
  ),

  race_year: yearFormatter,
  race_id: raceIdFormatter,
  race_category: raceCategoryFormatter,
  diff: diffFormatter,

  // 翻訳非対象、そのまま表示
  car_number: rawFormatter,
  car_kind: rawFormatter,
  length: rawFormatter,
  race_name: rawFormatter,

  // 翻訳非対象、整数
  result_index: rawIntFormatter,

  // 翻訳非対象、小数
  driver_point: rawPointFormatter,
  total_driver_point: rawPointFormatter,
  total_driver_point_rank: rankFormatter,
  team_point: rawPointFormatter,
  total_team_point: rawPointFormatter,
  total_team_point_rank: rankFormatter,

  // 翻訳非対象、変換
  birthday: rawFormatter,
  round: rawPointFormatter,
};
