import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
} from '@mui/material';
import React, { memo } from 'react';
import { defined } from './Util';

export interface RequiredOptionButtonProps extends ButtonGroupProps {
  values: { id: string; value: string; available: boolean }[];
  selectedId: string;
  canUnselect?: boolean;
  onChanged?: (selectedId: string) => void;
  buttonProps?: ButtonProps;
  buttonPropsSelected?: ButtonProps;
}

const RequiredOptionButton = ({
  values,
  selectedId,
  canUnselect,
  onChanged,
  buttonProps,
  buttonPropsSelected,
  ...props
}: RequiredOptionButtonProps): React.ReactElement => {
  buttonProps = buttonProps ?? { variant: 'outlined' };
  buttonPropsSelected = buttonPropsSelected ?? { variant: 'contained' };
  return (
    <ButtonGroup {...props}>
      {values.map(({ id, value, available }) => {
        let props = buttonProps;
        if (id === selectedId) {
          props = { ...props, ...buttonPropsSelected };
        }
        return (
          <Button
            {...props}
            key={id}
            disabled={!available}
            onClick={() => {
              if (defined(canUnselect) && canUnselect) {
                const newValue = selectedId !== id ? id : '';
                onChanged?.(newValue);
              } else {
                if (selectedId !== id) {
                  onChanged?.(id);
                }
              }
            }}
          >
            {value}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default memo(RequiredOptionButton);
